import type { WebSocketStatus } from '@thinkalpha/common/push/socket.js';
import type { LocateAccount } from 'src/contracts/locates/platforms';
import type { LocateProvider } from 'src/contracts/locates/providers';
import type { RootState } from 'src/store';
import type { StoredLocateOrder } from 'src/store/reducers/locates';
import type { SoundAlert } from 'src/store/types/locates';

export function getLocatesAccounts(state: RootState): LocateAccount[] {
    return state.locates.accounts;
}

export function getLocatesOrders(state: RootState): StoredLocateOrder[] {
    return state.locates.orders;
}

export function getLocatesStatus(state: RootState): WebSocketStatus | 'uninitialized' {
    return state.locates.status;
}

export function getIsLocatesBootstrapComplete(state: RootState): boolean {
    return state.locates.isLocatesBootstrapComplete;
}

export function getEnabledLocatesSoundAlerts(state: RootState): SoundAlert[] {
    return state.locates.enabledSoundAlerts;
}

export function getLocatesProviders(state: RootState): LocateProvider[] {
    return state.locates.providers;
}
