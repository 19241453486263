import type { RootState } from '..';
import type { ContainerViewModel } from '../types';

export const getContainerViewModels = (state: RootState): Record<string, ContainerViewModel> => state.container.byId;

export const getMaximizedContainerId = (state: RootState) => {
    const containers = getContainerViewModels(state);

    // sanity check that the maximized container id exists in the containers
    if (state.container.maximizedContainerId && !containers[state.container.maximizedContainerId]) {
        return undefined;
    }

    return state.container.maximizedContainerId;
};
