import type { Idea, ScannerPlan } from '@thinkalpha/platform-ws-client/contracts/ideas/index.js';

export type SetScannerDisplayingResultsAction = {
    type: 'setScannerDisplayingResults';
    tabId: string;
    isShowing: boolean;
};

export type SetScannerDisplayingBuilderAction = {
    type: 'setScannerDisplayingBuilder';
    tabId: string;
    isShowing: boolean;
};

export type SetScannerIdeaAction = {
    type: 'setScannerIdea';
    tabId: string;
    idea: Idea & { plan: ScannerPlan };
};

export type StoreScannerSplitPercentageAction = {
    type: 'storeScannerSplitPercentage';
    tabId: string;
    splitPercentage: number;
};

export const setScannerDisplayingBuilder = (tabId: string, isShowing: boolean): SetScannerDisplayingBuilderAction => ({
    tabId,
    isShowing,
    type: 'setScannerDisplayingBuilder',
});

export const setScannerDisplayingResults = (tabId: string, isShowing: boolean): SetScannerDisplayingResultsAction => ({
    tabId,
    isShowing,
    type: 'setScannerDisplayingResults',
});

export const storeScannerSplitPercentage = (
    tabId: string,
    splitPercentage: number,
): StoreScannerSplitPercentageAction => ({
    tabId,
    splitPercentage,
    type: 'storeScannerSplitPercentage',
});

export const setScannerIdea = (tabId: string, idea: Idea & { plan: ScannerPlan }): SetScannerIdeaAction => ({
    tabId,
    idea,
    type: 'setScannerIdea',
});

export type ScannerActions =
    | SetScannerDisplayingBuilderAction
    | SetScannerDisplayingResultsAction
    | StoreScannerSplitPercentageAction
    | SetScannerIdeaAction;
