import { noop } from 'es-toolkit';
import { type END, type EventChannel, eventChannel } from 'redux-saga';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import { tg } from 'src/features/onboarding';
import { tourGuideSteps } from 'src/features/onboarding/tourGuideSteps';
import {
    type ThinkAlphaTourGuideStepsWithWidgetContext,
    type TourGuideExitEmission,
} from 'src/features/onboarding/types';
import {
    exitTourGuide,
    setActiveTourContainer,
    setIsTourGuideActive,
    type UpdateTourStepTargetsAction,
    type StartTourGuideAction,
    type ModifyTourStepsAction,
    updateTourSteps,
} from 'src/store/actions/onboarding';
import { getWidgetTourSteps } from 'src/store/selectors/onboarding';

// create a channel for redux to emit an exit message to trigger the exit saga from within the tg.onAFterExit function
function createTourGuideChannel(): EventChannel<TourGuideExitEmission> {
    return eventChannel<TourGuideExitEmission>((emit: (input: TourGuideExitEmission | END) => void) => {
        tg.onAfterExit(() => {
            emit({ type: 'TG_EXIT' });
        });
        return () => noop() as unknown as END;
    });
}

function* onTourGuideExit() {
    yield put(exitTourGuide());
}

export function* initTourGuide() {
    const tourGuideChannel: EventChannel<TourGuideExitEmission> = yield call(createTourGuideChannel);

    while (true) {
        const event: TourGuideExitEmission = yield take(tourGuideChannel);
        if (event.type === 'TG_EXIT') {
            yield call(onTourGuideExit);
        }
    }
}

export function* handleTourGuideStart(
    action: StartTourGuideAction,
): Generator<any, void, ThinkAlphaTourGuideStepsWithWidgetContext> {
    yield put(setActiveTourContainer(action.payload.containerId));
    yield put(setIsTourGuideActive(true));
    yield* updateTourStepTargets(action);
    yield call(() => tg.start());
}

// This saga is required - it forces TG to re-evaluate step targets in order to get updated dom references
// after dom manipulation (re-renders from react cause original dom references to no longer exist)
// example - SearchAlpha2.0 - switching to guidedMode causes a re-render of the input element, so the target references the old dom node and can't find it after switching back guidedMode
export function* updateTourStepTargets(
    action: UpdateTourStepTargetsAction | StartTourGuideAction,
): Generator<any, void, ThinkAlphaTourGuideStepsWithWidgetContext> {
    const tourGuideSteps = yield select(getWidgetTourSteps);
    const widgetSteps = tourGuideSteps[action.payload.widgetType][action.payload.containerId];
    yield call((): Promise<unknown> => {
        return tg.setOptions({
            ...tg.options,
            steps: widgetSteps.map((step) => {
                // reference to the target MUST change for TG to re-calculate target refs
                // All other options on documentation have been exhausted - this works.
                const targetRefClone = step.target;
                return {
                    ...step,
                    target: targetRefClone,
                };
            }),
        });
    });
    // ensure tg client is only refreshed if the tour is started
    yield call(() => {
        if (!tg.activeStep) return;
        tg.refresh();
    });
}

// modify tour steps to gain access to component-level scopes and update steps in store
export function* modifyTourSteps(
    action: ModifyTourStepsAction,
): Generator<any, void, ThinkAlphaTourGuideStepsWithWidgetContext> {
    // make a copy of original widget tour steps
    const widgetSteps = [...tourGuideSteps[action.payload.widgetType]];

    // replace steps with widget-level context steps
    const modifiedSteps = widgetSteps.map((step) => {
        const stepToReplace = action.payload.modifiedSteps.find((modStep) => modStep.title === step.title);
        return stepToReplace ? { ...step, ...stepToReplace } : step;
    });

    yield put(updateTourSteps(action.payload.widgetType, action.payload.containerId, modifiedSteps));
}

export function* handleTourGuideExit() {
    yield put(setActiveTourContainer(null));
    yield put(setIsTourGuideActive(false));
}

export function* onboardingSagas() {
    if (APP_ELECTRON) return; //Skip sagas if app is native (electron)
    yield all([
        takeLatest('tour-guide::start', handleTourGuideStart),
        takeLatest('tour-guide::exit', handleTourGuideExit),
        takeLatest('bootstrap::begin', initTourGuide),
        takeLatest('tour-guide::updateTourStepTargets', updateTourStepTargets),
        takeLatest('tour-guide::modifyTourSteps', modifyTourSteps),
    ]);
}
