import { getTopStrategiesRecordsForUser } from './http/strategies';
import type { FetchQueryOptions } from '@tanstack/react-query';
import { type Idea, type TopIdeas } from '@thinkalpha/platform-ws-client/contracts/ideas/index.js';
import type { TopStrategies } from 'src/contracts/strategy';
import { ONE_HOUR } from 'src/lib/util/timeConstants';
import { type IsomorphicBindings } from 'src/types/bindings';

/**
 * Get top strategies for a user
 *
 * @returns an object with queryKey and queryFn
 */
export function getTopStrategiesForUserQuery(): FetchQueryOptions<TopStrategies> {
    return {
        queryKey: ['top-strategies'],
        queryFn: () => getTopStrategiesRecordsForUser(),
        staleTime: ONE_HOUR,
    };
}

export function upsertRecentlyCreatedStrategyQueryData(
    strategyToUpsert: Idea,
    queryClient: IsomorphicBindings['QueryClient'],
) {
    queryClient.setUserQueryData(['top-strategies'], (topStrategies: TopIdeas) => {
        return {
            recentlyAccessed: [
                strategyToUpsert,
                ...topStrategies.recentlyAccessed.filter((x) => x.id !== strategyToUpsert.id),
            ],
            recentlyCreated: [
                strategyToUpsert,
                ...topStrategies.recentlyCreated.filter((x) => x.id !== strategyToUpsert.id),
            ],
        };
    });
}

export function updateTopStrategiesStrategyName(
    strategyToRename: Idea,
    queryClient: IsomorphicBindings['QueryClient'],
) {
    queryClient.setUserQueryData(['top-strategies'], (topStrategies: TopIdeas) => {
        return {
            recentlyAccessed: topStrategies.recentlyAccessed.map((x) =>
                x.id === strategyToRename.id ? { ...x, name: strategyToRename.name } : x,
            ),
            recentlyCreated: topStrategies.recentlyCreated.map((x) =>
                x.id === strategyToRename.id ? { ...x, name: strategyToRename.name } : x,
            ),
        };
    });
}

export function removeStrategyQueryDataStrategy(id: string, queryClient: IsomorphicBindings['QueryClient']) {
    queryClient.setUserQueryData(['top-strategies'], (topStrategies: TopIdeas) => {
        return {
            recentlyAccessed: topStrategies.recentlyAccessed.filter((x) => x.id !== id),
            recentlyCreated: topStrategies.recentlyCreated.filter((x) => x.id !== id),
        };
    });
}
