import { IndicatorImportRefModel } from '../IndicatorImportRef';
import type { SelfAliasIndicatorFormula } from '@thinkalpha/platform-ws-client/contracts/ideas/conditions.js';
import { type FormulaService } from 'src/services/FormulaService';

export class IndicatorPresetModel {
    readonly #imports: IndicatorImportRefModel[];
    readonly #selfAlias: string;
    readonly #formula: string;

    readonly #normalizedFormula: string;

    private constructor(
        selfAlias: string,
        formula: string,
        imports: IndicatorImportRefModel[],
        normalizedFormula: string,
    ) {
        this.#selfAlias = selfAlias;
        this.#formula = formula;
        this.#imports = imports;

        this.#normalizedFormula = normalizedFormula;
    }

    get selfAlias() {
        return this.#selfAlias;
    }

    get formula() {
        return this.#formula;
    }

    get imports() {
        return this.#imports;
    }

    static async fromSelfAliasIndicatorFormula(
        indicatorPresetRef: SelfAliasIndicatorFormula,
        formulaService: FormulaService,
    ) {
        const normalizedFormula = await formulaService.normalize(
            indicatorPresetRef.formula,
            indicatorPresetRef.imports,
        );

        return new IndicatorPresetModel(
            indicatorPresetRef.selfAlias,
            indicatorPresetRef.formula,
            indicatorPresetRef.imports.map((i) => IndicatorImportRefModel.fromIndicatorImportRef(i)),
            normalizedFormula,
        );
    }

    static equals(a: IndicatorPresetModel, b: IndicatorPresetModel) {
        return a.#normalizedFormula === b.#normalizedFormula;
    }

    equals(b: IndicatorPresetModel) {
        return IndicatorPresetModel.equals(this, b);
    }

    toContract(): SelfAliasIndicatorFormula {
        return {
            selfAlias: this.#selfAlias,
            formula: this.#formula,
            imports: this.#imports.map((i) => i.toContractType()),
        };
    }

    toJSON() {
        return this.toContract();
    }
}
