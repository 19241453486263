import { Idea, WatchlistPlan } from '@thinkalpha/platform-ws-client/contracts/ideas/index.js';
import { union } from 'es-toolkit';
import { ReactiveInjectable, reacts, inject, injectable } from 'src/features/ioc';
import { WATCHLIST_CHANGE_EVENT, WatchlistPlanDTO } from 'src/features/watchlist/dtos/WatchlistPlanDTO';
import type { WatchListWidgetModel } from 'src/models/WatchListWidgetModel';
import { userDoubleClickedSymbolFromTable } from 'src/store/actions/widgets/results';
import { setWatchlistIdea } from 'src/store/actions/widgets/watchlist';
import { WatchlistWidgetViewModel } from 'src/store/types';
import type { ReactBindings } from 'src/types/bindings';

@injectable()
export class WatchListWidgetModelImpl extends ReactiveInjectable implements WatchListWidgetModel {
    constructor(
        @inject('WidgetDataModel') @reacts private widgetData: ReactBindings['WidgetDataModel'],
        @inject('Store') private store: ReactBindings['Store'],
    ) {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);

        this.#plan = WatchListWidgetModelImpl.createEmptyWatchlistPlan();
        this.#bindEventListeners();
    }

    static createEmptyWatchlistPlan(): WatchlistPlanDTO {
        return new WatchlistPlanDTO({
            isDirty: false,
            includeList: [],
        });
    }

    init(tabId: string) {
        this.widgetData.init(tabId);
        this.plan = WatchListWidgetModelImpl.createEmptyWatchlistPlan();
        this.#bindEventListeners();
    }

    userSelectedSymbol(symbol: string) {
        this.store.dispatch(userDoubleClickedSymbolFromTable(this.widgetData.tabId, symbol));
    }

    #plan: WatchlistPlanDTO;
    @reacts private set plan(plan) {
        this.#plan = plan;
        this.#bindEventListeners();
    }
    get plan() {
        return this.#plan;
    }

    get idea() {
        return this.widget.idea;
    }

    #onWatchlistChange() {
        this.rerender();
    }

    #bindEventListeners() {
        const listener = this.#onWatchlistChange.bind(this);
        this.#plan.changeTarget.addEventListener(WATCHLIST_CHANGE_EVENT, listener);
        this.disposableStack.defer(() => {
            this.#plan.changeTarget.removeEventListener(WATCHLIST_CHANGE_EVENT, listener);
        });
    }

    addSymbol(symbol: string) {
        const userSubmittedSymbols = symbol
            .toUpperCase()
            .replace(/[\r\n ]/g, ',')
            .split(',')
            .map((x) => x.trim())
            .filter(Boolean);

        this.plan.setIncludeList((includeList) => {
            const currentInclusionList = includeList.map((x) => x.toUpperCase()) ?? [];
            return union(currentInclusionList, userSubmittedSymbols);
        });
    }

    removeSymbol(symbol: string) {
        this.plan.setIncludeList((includeList) => {
            const currentInclusionList = includeList.map((x) => x.toUpperCase()) ?? [];
            return currentInclusionList.filter((x) => x !== symbol.toUpperCase());
        });
    }

    get widget() {
        return this.widgetData.widget as WatchlistWidgetViewModel;
    }

    async useIdea(idea: Idea & { plan: WatchlistPlan }) {
        this.store.dispatch(setWatchlistIdea(this.widgetData.tabId, idea));

        this.plan = WatchlistPlanDTO.fromPlan(idea.plan);
        this.#bindEventListeners();
    }
}
