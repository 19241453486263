import { IndicatorImportModel } from '../IndicatorImport';
import {
    type IndicatorRef,
    type ConcreteIndicator,
    type IndicatorImportRef,
} from '@thinkalpha/platform-ws-client/contracts/dictionary.js';
import type { ThinkAlphaQueryClient } from 'src/lib/config/query-client';
import { getIndicatorFromRefQuery } from 'src/queries/dictionary';

export class IndicatorImportRefModel implements IndicatorImportRef {
    #alias: string;
    #id: string;
    #version: number;

    get alias(): string {
        return this.#alias;
    }

    private constructor({ alias, id, version }: { alias: string; id: string; version: number }) {
        this.#alias = alias;
        this.#id = id;
        this.#version = version;
    }

    static fromIndicatorImportRef(viewModel: IndicatorImportRef): IndicatorImportRefModel {
        return new IndicatorImportRefModel(viewModel);
    }

    static fromIndicatorRef(
        ref: IndicatorRef | ConcreteIndicator,
        imports: readonly IndicatorImportRefModel[],
        preferredAlias?: string,
    ) {
        const importModel = imports.find((importModel) => importModel.doesReference(ref));

        if (importModel) {
            return importModel;
        }

        const aliasPrefix = preferredAlias
            ? preferredAlias
            : 'symbol' in ref
              ? ref.symbol
              : `_${ref.id.replaceAll('-', '')}_${ref.version}`;
        let alias = aliasPrefix;
        let found;
        let i = 0;
        do {
            found = imports.find((importModel) => importModel.alias === alias);

            if (found && !found.doesReference(ref)) {
                i++;
                alias = `${aliasPrefix}_${i}`;
            }
        } while (found);

        return new IndicatorImportRefModel({
            alias,
            id: ref.id,
            version: ref.version,
        });
    }

    doesReference(b: IndicatorRef) {
        return this.id === b.id && this.version === b.version;
    }

    static equals(a: IndicatorImportRef, b: IndicatorImportRef) {
        return a.id === b.id && a.version === b.version && a.alias === b.alias;
    }

    equals(b: IndicatorImportRef) {
        return this.id === b.id && this.version === b.version && this.alias === b.alias;
    }

    get id(): string {
        return this.#id;
    }

    toContractType(): IndicatorImportRef {
        return {
            alias: this.#alias,
            id: this.#id,
            version: this.#version,
        };
    }

    /**
     * Materialize the ref model
     */
    toIndicator(concreteIndicator: ConcreteIndicator): IndicatorImportModel {
        return IndicatorImportModel.fromIndicatorImport({
            alias: this.#alias,
            ...concreteIndicator,
        });
    }

    async fetchIndicator(queryClient: ThinkAlphaQueryClient): Promise<IndicatorImportModel> {
        const concreteIndicator = await queryClient.fetchUserQuery(getIndicatorFromRefQuery(this));
        return this.toIndicator(concreteIndicator);
    }

    get version(): number {
        return this.#version;
    }
}
