import type { ContainerLayoutViewModel, ContainerViewModel, WidgetTabViewModel } from '../types';
import { type IfThenResearchPlan, type Idea } from '@thinkalpha/platform-ws-client/contracts/ideas/index.js';
import type { WidgetCreationInfo, WidgetType } from 'src/contracts/workspace';

export type AddContainerAction = {
    type: 'addContainer';
    container: ContainerViewModel;
    initialTab: WidgetTabViewModel;
    layout: ContainerLayoutViewModel;
};

export const addContainer = (
    container: ContainerViewModel,
    initialTab: WidgetTabViewModel,
    layout: ContainerLayoutViewModel,
): AddContainerAction => ({
    type: 'addContainer',
    container,
    initialTab,
    layout,
});

export type RemoveContainerAction = {
    type: 'removeContainer';
    container: ContainerViewModel;
};

export const removeContainer = (container: ContainerViewModel): RemoveContainerAction => ({
    type: 'removeContainer',
    container,
});

export type SetActiveContainerAction = {
    type: 'setActiveContainer';
    containerId: string;
};

export const setActiveContainer = (containerId: string): SetActiveContainerAction => ({
    type: 'setActiveContainer',
    containerId,
});

export type AddWidgetToDashboardAction = {
    type: 'addWidgetToDashboard';
    widget: WidgetCreationInfo;
};

export const addWidgetToDashboard = (widgetType: WidgetType): AddWidgetToDashboardAction => ({
    type: 'addWidgetToDashboard',
    widget: { creationType: 'from-widget-default', widgetType },
});
export const createAlphaLensFromSymbol = ({
    defaultSymbol,
    channelId,
}: {
    defaultSymbol: string;
    channelId: string | null;
}): AddWidgetToDashboardAction => ({
    type: 'addWidgetToDashboard',
    widget: {
        creationType: 'new-alpha-lens',
        defaultSymbol,
        channelId,
    },
});
export const createChartFromSymbol = ({
    defaultSymbol,
    channelId,
}: {
    defaultSymbol: string;
    channelId: string | null;
}): AddWidgetToDashboardAction => ({
    type: 'addWidgetToDashboard',
    widget: {
        creationType: 'new-time-series',
        defaultSymbol,
        channelId,
    },
});

export const createSearchAlphaWithIdea = ({
    channelId,
    idea,
}: {
    channelId: string | null;
    idea: Idea & { plan: IfThenResearchPlan };
}): AddWidgetToDashboardAction => ({
    type: 'addWidgetToDashboard',
    widget: {
        creationType: 'new-searchalpha',
        channelId,
        idea,
    },
});

/**
 * @deprecated We don't support creating results with an Idea literal any more, only Idea by ID
 */
export const createResultsWithStrategy = ({
    defaultSymbol,
    channelId,
    strategies,
}: {
    defaultSymbol: string;
    channelId: string | null;
    strategies: (Idea | string)[];
}): AddWidgetToDashboardAction => ({
    type: 'addWidgetToDashboard',
    widget: {
        creationType: 'new-strategy',
        defaultSymbol,
        channelId,
        strategies,
    },
});

export const cloneWidgetToDashboard = ({
    containerId,
    tabId,
}: {
    containerId: string;
    tabId: string;
}): AddWidgetToDashboardAction => ({
    type: 'addWidgetToDashboard',
    widget: {
        creationType: 'cloned',
        baseWidgetTabId: tabId,
        baseWidgetContainerId: containerId,
    },
});
export type SetMaximizedContainerAction = {
    type: 'setMaximizedContainer';
    containerId: string | undefined;
};

export const setMaximizedContainer = (containerId: string | undefined): SetMaximizedContainerAction => ({
    type: 'setMaximizedContainer',
    containerId,
});

export type ContainerAction =
    | AddWidgetToDashboardAction
    | SetActiveContainerAction
    | AddContainerAction
    | RemoveContainerAction
    | SetMaximizedContainerAction;
