import type { Idea, WatchlistPlan } from '@thinkalpha/platform-ws-client/contracts/ideas/index.js';

export type SetWatchlistIdeaAction = {
    type: 'setWatchlistIdea';
    tabId: string;
    idea: Idea & { plan: WatchlistPlan };
};

export const setWatchlistIdea = (tabId: string, idea: Idea & { plan: WatchlistPlan }): SetWatchlistIdeaAction => ({
    tabId,
    idea,
    type: 'setWatchlistIdea',
});

export type WatchlistActions = SetWatchlistIdeaAction;
