import type { ResultsAreaModel } from '.';
import type { Instant } from '@js-joda/core';
import { inject, injectable, ReactiveInjectable, reacts } from 'src/features/ioc';
import type { ReactBindings } from 'src/types/bindings';

@injectable()
export class ResultsAreaModelImpl extends ReactiveInjectable implements ResultsAreaModel {
    get columnTemplate() {
        return this.adapter.columnTemplate;
    }

    set columnTemplate(value) {
        this.adapter.columnTemplate = value;
    }

    constructor(@inject('ResultsAreaAdapterModel') @reacts private adapter: ReactBindings['ResultsAreaAdapterModel']) {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);
    }

    get filter() {
        return this.adapter.filter;
    }

    get filterIsSticky() {
        return this.adapter.filterIsSticky;
    }

    #lastSubmit?: Instant;

    get lastSubmit() {
        return this.#lastSubmit;
    }

    @reacts set lastSubmit(value: Instant | undefined) {
        this.#lastSubmit = value;
    }

    #tableDestroyKey: string | undefined;

    get tableDestroyKey() {
        return this.#tableDestroyKey;
    }

    @reacts set tableDestroyKey(tableDestroyKey: string | undefined) {
        this.#tableDestroyKey = tableDestroyKey;
    }

    get scrolling() {
        return this.adapter.scrolling;
    }

    set scrolling(value: boolean) {
        this.adapter.scrolling = value;
    }

    toggleStickyFilter() {
        this.adapter.toggleStickyFilter();
    }

    get universeId() {
        return this.adapter.universeId;
    }

    set universeId(value) {
        this.adapter.universeId = value;
    }

    updateFilter(newFilter: string) {
        this.adapter.updateFilter(newFilter);
    }

    userSelectedSymbol(symbol: string) {
        this.adapter.userSelectedSymbol(symbol);
    }
}
