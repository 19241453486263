import type {
    ConcreteIdea,
    IdeaPatch,
    IdeaRecord,
    NewIdea,
    TopIdeas,
} from '@thinkalpha/platform-ws-client/contracts/ideas/index.js';
import Dataloader from 'dataloader';
import { api } from 'src/api';

export function getTopIdeasRecords(): Promise<TopIdeas> {
    return api.get<TopIdeas>('/strategies/top').then((x) => x.data);
}

export function patchIdea(id: string, patch: IdeaPatch): Promise<ConcreteIdea> {
    return api.patch<ConcreteIdea>(`/strategies/${id}`, patch).then((x) => x.data);
}

export function createIdea(idea: NewIdea): Promise<ConcreteIdea> {
    return api.post<ConcreteIdea>('/strategies', idea).then((x) => x.data);
}

export const ideaRecordLoader = new Dataloader(
    async (ids: string[]): Promise<IdeaRecord[]> => {
        const result = (
            await api.get<IdeaRecord[]>('/strategies/records', {
                params: { ids },
            })
        ).data;

        const map = new Map(result.map((x) => [x.id, x]));

        return ids.map((id) => map.get(id)!);
    },
    { cache: false },
);

export const ideaLoader = new Dataloader(
    async (ids: string[]): Promise<ConcreteIdea[]> => {
        const result = (await api.get<ConcreteIdea[]>(`/strategies`, { params: { ids } })).data;

        const map = new Map(result.map((x) => [x.id, x]));

        return ids.map((id) => map.get(id)!);
    },
    { cache: false },
);
