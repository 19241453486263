import type {
    ConcreteIndicatorViewModel,
    IndicatorViewModel,
    IndicatorImportViewModel,
} from '../../contracts/dictionary-view-model';
import { getIndicatorsFromSymbols$ } from './dictionary';
import type { CallNode } from '@thinkalpha/language-services';
import { AstNodeType, iterateAst, parser } from '@thinkalpha/language-services';
import { uniqBy } from 'es-toolkit';
import { firstValueFrom } from 'rxjs';
import type { IndicatorViewModelOrImportViewModel } from 'src/lib/dictionaryModel';
import { isIndicatorViewModelImport } from 'src/lib/dictionaryModel';

/**
 * Creates a single import from an existing indicator where there is no
 * possibility for alias conflict
 **/
export function createImport(indicator: ConcreteIndicatorViewModel): IndicatorImportViewModel {
    return { ...indicator, alias: indicator.symbol };
}

/**
 * Creates a single import alias for an existing indicator that is unique from
 * aliases present in an array of already existing imports.
 *
 * @param indicator an indicator
 * @param list an array of imports
 *
 * @returns a unique alias string
 */
export function createImportAlias(indicator: IndicatorViewModel, list: IndicatorImportViewModel[]): string {
    const incr = (alias: string): string => {
        const counters = alias.match(/\d+$/);
        if (counters && counters[0]) {
            let counter = Number(counters[0]);
            counter += 1;
            return aliasWithAutoIncr(`${alias.replace(/\d+$/, '')}${counter}`);
        }
        return `${alias}1`;
    };

    const aliasWithAutoIncr = (alias: string): string => {
        const importWithMatchingAlias = list.find((x) => x.alias === alias);
        if (!importWithMatchingAlias) return alias;

        /**
         * If new indicator has an alias match, but has a key that is unique
         * from that alias match, append the key name to the alias name
         * before any incrementing is done
         *
         * ex: Symbol becomes ExecutionSymbol, if Symbol alias already exists
         * and new Symbol key is Execution
         *
         **/
        if (indicator.key && indicator.key !== importWithMatchingAlias.key) {
            const aliasWithKey = indicator.key + alias;

            const alreadyUsedWithKey = list.find((x) => x.alias === aliasWithKey);
            if (!alreadyUsedWithKey) return aliasWithKey;

            return incr(aliasWithKey);
        }

        return incr(alias);
    };

    return aliasWithAutoIncr(indicator.symbol);
}

export function mergeImports(
    existingImports: IndicatorImportViewModel[],
    /**
     * Pass new import as IndicatorImport instead of Indicator if you would
     * like to explicitly set the alias.
     */
    newImports: IndicatorViewModelOrImportViewModel[],
    dontDuplicate: boolean,
) {
    const indsNotAlreadyImported = newImports.filter((newImport) => {
        return !existingImports.some(
            (existingImport) =>
                existingImport.id === newImport.id && (dontDuplicate || existingImport.version === newImport.version),
        );
    });

    const existingImportsCopy = [...existingImports];
    const indsReadyToImport = indsNotAlreadyImported.map((indicator) => {
        const newAlias = isIndicatorViewModelImport(indicator)
            ? indicator.alias
            : createImportAlias(indicator, existingImportsCopy);
        const newImport: IndicatorImportViewModel = { ...indicator, alias: newAlias };
        existingImportsCopy.push(newImport);
        return newImport;
    });

    return [...existingImports, ...indsReadyToImport];
}

export function getCallNodesFromFormula(formula: string) {
    return iterateAst(parser(formula, {}).root)
        .filter((x): x is CallNode => x.type === AstNodeType.call)
        .toArray();
}

export async function getIndicatorsFromFormula(formula: string, key: string) {
    return await firstValueFrom(
        getIndicatorsFromSymbols$(
            getCallNodesFromFormula(formula).map((node) => node.source),
            key,
        ),
    );
}

/**
 * Based on an array of indicators, return an array of indicators with unique "symbol" properties.
 * If any indicators have the same symbol property, prioritize the one with source null
 * (i.e. ThinkAlpha indicator) over other source (i.e. custom indicator).
 *
 * Not case sensitive; avg and AVG are considered duplicates.
 **/
export function getUniqueIndicatorsBySymbol<T extends IndicatorViewModel>(inds: T[]): T[] {
    return uniqBy(inds, (x) => x.symbol.toLowerCase());
}
