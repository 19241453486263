// sort-imports-ignore
import '@js-joda/timezone';
import 'src/components/table-view/app-wide';
import 'src/lib/charting';
import 'src/lib/errorTracking';
import 'src/lib/i18next';
import 'src/lib/onesignal';
import 'src/lib/table';
import { container } from 'src/StaticContainer';
import { localforage } from 'src/lib/serialization/localForage';
import '@thinkalpha/common/polyfills/rxjs-disposable.js';
// Styles
import 'src/styles/index.scss';

// import highcharts from 'highcharts';
// import highchartsTheme from 'highcharts/themes/dark-unica';
// highchartsTheme(highcharts);

const log = container.get('Logger').getSubLogger({ name: 'prelude' });

window.localforage = localforage;
window.logger = log;
