/** Prefixes for room names; room formats are prefix, a dash, and an id */
export enum RoomPrefix {
    Backtest = 'backtests',
    Account = 'accounts',
    workspace = 'workspace',
}

/** Commands from the client to the server */
export enum SocketCommands {
    create = 'create',
    joinWorkspace = 'join-workspace',
    leaveWorkspace = 'leave-workspace',
    status = 'status',
    closeRoom = 'closeRoom',
}

/** Events from the server to the client */
export enum SocketEvents {
    activeApp = 'active-app::claim',
    checkAppVersion = 'check-app-version',
    LocateOrder = 'locate-order',
    WorkspaceUpdated = 'workspace-update',
    BacktestStatus = 'backtest-status',
}

/** Base interface for client to server messages */
// export interface ClientMessage {}

/** Base interface for server to client messages */
// export interface PushMessage {}
