import { rxapi, api } from '../api';
import { getImportViewModelsFromRefs } from '../lib/dictionary/dictionary';
import { processQuery } from './paging';
import { client } from './table';
import type { ConcreteIdea, NewIdea } from '@thinkalpha/platform-ws-client/contracts/ideas/index.js';
import type { AxiosResponse } from 'axios';
import type { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { container } from 'src/StaticContainer';
import type { IndicatorImportRefViewModel, IndicatorImportViewModel } from 'src/contracts/dictionary-view-model';
import type { ResourceQuery, ResourceQueryResponseWithMeta } from 'src/contracts/resource-query';
import type {
    ConcreteStrategy,
    NewStrategy,
    NewStrategyInstance,
    Strategy,
    StrategyInstance,
    StrategyPatch,
    StrategyPut,
    StrategyRecord,
    StrategyWithVersions,
    TopStrategies,
} from 'src/contracts/strategy';
import type { ResearchStrategy } from 'src/lib/strategyModel';
// we will kill off this file soon enough
// eslint-disable-next-line no-restricted-imports
import { ideaLoader } from 'src/queries/http/ideas';

const _log = container.get('Logger').getSubLogger({ name: 'strategy-service' });

export const getStrategyComplexityErrors = async (
    strategy: ResearchStrategy<IndicatorImportViewModel>,
): Promise<{ message: string }[]> => {
    return (await api.post(`/strategies/complexity-errors`, strategy)).data;
};

export const getStrategyRecordsQuery = async (
    query: ResourceQuery,
): Promise<ResourceQueryResponseWithMeta<StrategyRecord>> => {
    return (await api.get(`/strategies/query${processQuery(query)}`)).data;
};
export const getStrategyRecordsCountQuery = async (): Promise<ResourceQueryResponseWithMeta<StrategyRecord>> => {
    return (await api.get(`/strategies/query?$select=&$count=true`)).data;
};

export function getAllStrategyRecords(): Observable<StrategyRecord[]> {
    return rxapi.get<StrategyRecord[]>('/strategies/records/all').pipe(map((x) => x.data));
}

// export const strategyRecordLoader = new Dataloader(
//     async (ids: string[]): Promise<StrategyRecord[]> => {
//         const result = (
//             await api.get<StrategyRecord[]>('/strategies/records', {
//                 params: { ids },
//             })
//         ).data;

//         const map = new Map(result.map((x) => [x.id, x]));

//         return ids.map((id) => map.get(id)!);
//     },
//     { cache: false },
// );

export function getTopStrategyRecords(): Observable<TopStrategies> {
    return rxapi.get<TopStrategies>('/strategies/top').pipe(map((x) => x.data));
}

// export const ideaLoader = new Dataloader(
//     async (ids: string[]): Promise<ConcreteIdea[]> => {
//         const result = (await api.get<ConcreteIdea[]>(`/strategies`, { params: { ids } })).data;

//         const map = new Map(result.map((x) => [x.id, x]));

//         return ids.map((id) => map.get(id)!);
//     },
//     { cache: false },
// );

/**
 * @deprecated Use indicatorFromRefLoader on a per-strategy basis instead
 */
export async function mapStrategyPlanImportsRefsToMaterialized(
    strategy: Strategy<IndicatorImportRefViewModel>,
): Promise<Strategy<IndicatorImportViewModel>>;
export async function mapStrategyPlanImportsRefsToMaterialized(
    strategy: ConcreteStrategy<IndicatorImportRefViewModel>,
): Promise<ConcreteStrategy<IndicatorImportViewModel>>;
export async function mapStrategyPlanImportsRefsToMaterialized(
    strategy: Strategy<IndicatorImportRefViewModel> | ConcreteStrategy<IndicatorImportRefViewModel>,
): Promise<Strategy<IndicatorImportViewModel> | ConcreteStrategy<IndicatorImportViewModel>> {
    const imports = await getImportViewModelsFromRefs(strategy.plan.imports);

    return {
        ...strategy,
        plan: {
            ...strategy.plan,
            imports,
        },
    };
}

/**
 * @deprecated Use strategyLoader in conjunction with importRefsLoader as useQuery+useQuery or useQueries+useQueries
 */
export async function getStrategyByIdWithImports(id: string): Promise<ConcreteStrategy<IndicatorImportViewModel>> {
    const strategy = await ideaLoader.load(id);

    return mapStrategyPlanImportsRefsToMaterialized(strategy as ConcreteStrategy<IndicatorImportViewModel>) as Promise<
        ConcreteStrategy<IndicatorImportViewModel>
    >;
}

export function getStrategyVersion(
    id: string,
    version: number,
    expandImports: true,
): Observable<ConcreteStrategy<IndicatorImportViewModel> | undefined>;
export function getStrategyVersion(
    id: string,
    version: number,
    expandImports?: false,
): Observable<ConcreteStrategy | undefined>;
export function getStrategyVersion(
    id: string,
    version: number,
    expandImports?: boolean,
): Observable<ConcreteStrategy | undefined> {
    return rxapi
        .get<ConcreteStrategy>(`/strategies/${id}/${version}${expandImports ? '?$expand=imports' : ''}`)
        .pipe(map((x) => x.data));
}

export const getStrategiesByIds = async (ids: string[]): Promise<ConcreteStrategy[]> =>
    (await api.get(`strategies`, { params: { ids } })).data;

export const getStrategiesWithVersionsByIds = async (
    ids: string[],
    expandImports = false,
): Promise<StrategyWithVersions[]> =>
    (
        await api.get(`strategies/versions`, {
            params: { ids, ...(expandImports ? { $expand: 'imports' } : {}) },
        })
    ).data;

export function deleteStrategyById(id: string): Promise<AxiosResponse> {
    return api.delete(`/strategies/${id}`);
}

export function createStrategyWithMappedImports(strategy: NewIdea): Promise<ConcreteIdea> {
    return api.post<ConcreteIdea>('/strategies?$expand=imports', strategy).then((x) => x.data);
}

/**
 * @deprecated no rxjs
 */
export function createStrategy(
    strategy: NewStrategy,
    expandImports: true,
): Observable<ConcreteStrategy<IndicatorImportViewModel>>;
export function createStrategy(strategy: NewStrategy): Observable<ConcreteStrategy>;
export function createStrategy(strategy: NewStrategy, expandImports?: true): Observable<ConcreteStrategy> {
    return rxapi
        .post<ConcreteStrategy>(`/strategies${expandImports ? '?$expand=imports' : ''}`, strategy)
        .pipe(map((x) => x.data));
}

export function putStrategyP(id: string, strategySubset: StrategyPut): Promise<ConcreteStrategy> {
    return api.put<ConcreteStrategy>(`/strategies/${id}`, strategySubset).then((x) => x.data);
}

/**
 * @deprecated no rxjs
 */
export function patchStrategy(id: string, patch: StrategyPatch): Observable<ConcreteStrategy> {
    return rxapi.patch<ConcreteStrategy>(`/strategies/${id}`, patch).pipe(map((x) => x.data));
}

/**
 * @deprecated no rxjs
 */
export function playStrategy({
    includeTags,
    ...newStrategyInstance
}: NewStrategyInstance & { includeTags?: boolean }): Observable<StrategyInstance> {
    const includeTagsQuery = includeTags ? `?includeTags=${includeTags}` : '';
    const res = rxapi.post<StrategyInstance>(`/strategies/instances${includeTagsQuery}`, newStrategyInstance).pipe(
        map((x) => x.data),
        tap(({ tableCookie }) => {
            if (tableCookie) {
                client.addAccessCookie(tableCookie);
            }
        }),
    );
    return res;
}

export async function playStrategyP({
    includeTags,
    ...newStrategyInstance
}: NewStrategyInstance & { includeTags?: boolean }): Promise<StrategyInstance> {
    const includeTagsQuery = includeTags ? `?includeTags=${includeTags}` : '';
    const result = await api
        .post<StrategyInstance>(`/strategies/instances${includeTagsQuery}`, newStrategyInstance)
        .then((x) => x.data);

    if (result.tableCookie) {
        client.addAccessCookie(result.tableCookie);
    }

    return result;
}

// export function idOfStrategy(strategy: string | Strategy | StrategyRecord): string | undefined {
//     if (typeof strategy === 'string') return strategy;
//     else return strategy.id;
// }

/** @deprecated use createTable from services/table */
// export async function submitTablePayload(payload: CreateTablePayload): Promise<TableCreationResultWithColumns> {
//     return await api.post<TableCreationResultWithColumns>(`/tables`, payload).then((x) => x.data);
// }
