import type { RootInstance, RootState } from './index';

export const waitForState = async (store: RootInstance, predicate: (state: RootState) => boolean): Promise<void> => {
    return new Promise((resolve) => {
        if (predicate(store.getState())) {
            resolve();
            return;
        }

        const unsubscribe = store.subscribe(() => {
            if (predicate(store.getState())) {
                unsubscribe();
                resolve();
            }
        });
    });
};
