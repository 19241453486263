/**
 * Mimics Array.prototype.splice for strings.
 * @param str - The original string.
 * @param start - The start index to modify.
 * @param deleteCount - The number of characters to remove.
 * @param items - The strings to insert at the start index.
 * @returns A new string with the modifications applied.
 */
export function toSpliced(str: string, start: number, deleteCount: number, ...items: string[]): string {
    // Ensure start is a valid index
    const normalizedStart = Math.max(0, Math.min(start, str.length));

    // Ensure deleteCount is valid
    const normalizedDeleteCount = Math.max(0, Math.min(deleteCount, str.length - normalizedStart));

    // Perform the splicing operation
    const prefix = str.slice(0, normalizedStart);
    const suffix = str.slice(normalizedStart + normalizedDeleteCount);
    const middle = items.join('');

    return prefix + middle + suffix;
}
