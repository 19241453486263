import type { QueryObserverOptions } from '@tanstack/react-query';
import type { ConcreteUniverse, UniverseRecord } from '@thinkalpha/platform-ws-client/contracts/universe.js';
import { getUniverses, getDefaultUniverse, getUniverseById } from 'src/lib/universes';

/**
 * Generates a query to get a universe by an id
 *
 * @param id string id of the universe, or null
 * @returns an object with queryKey, queryFn, and enabled
 */
export function getUniverseByIdQuery(id: string): QueryObserverOptions<ConcreteUniverse | undefined> {
    return {
        queryKey: ['universe', id],
        queryFn: async () => await getUniverseById(id),
    };
}

/**
 * Get universes for a user.
 *
 * @returns an object with queryKey and queryFn
 */
export function getUniversesQuery(): QueryObserverOptions<UniverseRecord[]> {
    return {
        queryKey: ['universes'],
        queryFn: () => getUniverses(),
    };
}

/**
 * Generates a query to get the default universe
 *
 * @param id string id of the universe, or null
 * @returns an object with queryKey and queryFn
 */
export function getDefaultUniverseQuery(): QueryObserverOptions<ConcreteUniverse | undefined> {
    return {
        queryKey: ['default-universe'],
        queryFn: () => getDefaultUniverse(),
    };
}
