import type { ScannerWidgetViewModel, ScreenerWidgetViewModel, SearchAlphaWidgetViewModel } from '../types';
import type { Idea, ScannerPlan, ScreenerPlan } from '@thinkalpha/platform-ws-client/contracts/ideas/index.js';
import { BinaryLogicalOperator } from 'src/contracts/language';

export const createDefaultSearchAlphaWidget = (
    values: Partial<SearchAlphaWidgetViewModel> = {},
): SearchAlphaWidgetViewModel => {
    return {
        type: 'searchalpha',
        channelId: null,
        displayingResults: true,
        displayingForm: true,
        displayingIdeas: true,
        splitPercentage: 50,
        filter: null,
        universeId: null,
        columnTemplate: null,
        scrolling: false,
        idea: {
            name: null,
            id: undefined,
            key: 'ticker',
            plan: {
                type: 'if-then',
                root: {
                    type: 'group',
                    id: 'indicator',
                    collapsed: false,
                    description: null,
                    enabled: true,
                    name: null,
                    lines: [],
                    operator: BinaryLogicalOperator.and,
                },
                imports: [],
            },
            defaultColumnTemplateId: null,
            defaultUniverseId: null,
            description: null,
            isTemplate: false,
        },
        ...values,
    };
};

export const createDefaultScreenerWidget = (values: Partial<ScreenerWidgetViewModel> = {}): ScreenerWidgetViewModel => {
    const defaultIdea: Idea & { plan: ScreenerPlan } = {
        name: null,
        id: undefined,
        description: null,
        defaultUniverseId: null,
        defaultColumnTemplateId: null,
        isTemplate: false,
        key: 'ticker',
        plan: {
            type: 'screener',
            mode: 'include-all',
            conditions: [],
            excludeList: [],
            includeList: [],
        },
    };

    return {
        type: 'screener',
        channelId: values.channelId ?? null,
        displayingResults: values.displayingResults ?? true,
        displayingForm: values.displayingForm ?? true,
        splitPercentage: values.splitPercentage ?? 50,
        filter: values.filter ?? null,
        columnTemplate: values.columnTemplate ?? null,
        scrolling: values.scrolling ?? false,
        idea: values.idea ?? defaultIdea,
        universeId: values.universeId ?? null,
    };
};

export const createDefaultScannerWidget = (values: Partial<ScannerWidgetViewModel> = {}): ScannerWidgetViewModel => {
    const defaultIdea: Idea & { plan: ScannerPlan } = {
        name: null,
        id: undefined,
        description: null,
        defaultUniverseId: null,
        defaultColumnTemplateId: null,
        isTemplate: false,
        key: 'ticker',
        plan: {
            type: 'scanner',
            mode: 'include-all',
            conditions: [],
        },
    };

    return {
        type: 'scanner',
        channelId: values.channelId ?? null,
        displayingResults: values.displayingResults ?? true,
        displayingForm: values.displayingForm ?? true,
        splitPercentage: values.splitPercentage ?? 50,
        filter: values.filter ?? null,
        columnTemplate: values.columnTemplate ?? null,
        scrolling: values.scrolling ?? false,
        universeId: values.universeId ?? null,
        idea: values.idea ?? defaultIdea,
    };
};
