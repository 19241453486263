import type { AppAction } from '../actions';
import type { ContainerViewModel } from '../types';
import { keyArrayToRecord } from '../utils';

export interface ContainerState {
    byId: Record<string, ContainerViewModel>;
    activeContainerId?: string;
    maximizedContainerId?: string | null;
}

export const initialState: ContainerState = {
    byId: {},
    activeContainerId: undefined,
    maximizedContainerId: undefined,
};

export const container = (state = initialState, action: AppAction): ContainerState => {
    switch (action.type) {
        case 'initializeWorkspace': {
            return {
                ...state,
                maximizedContainerId: action.workspace.maximizedContainerId,
                byId: {
                    ...state.byId,
                    ...keyArrayToRecord(action.containers, (container) => container.id),
                },
            };
        }

        case 'addContainer': {
            return {
                ...state,
                activeContainerId: action.container.id,
                byId: {
                    ...state.byId,
                    [action.container.id]: action.container,
                },
            };
        }

        case 'removeContainer': {
            const updatedContainersByKey: Record<string, ContainerViewModel> = { ...state.byId };
            delete updatedContainersByKey[action.container.id];
            const wasMaximizedContainer = action.container.id === state.maximizedContainerId;
            const wasActiveContainer = action.container.id === state.activeContainerId;

            return {
                ...state,
                byId: updatedContainersByKey,
                ...(wasMaximizedContainer ? { maximizedContainerId: undefined } : {}),
                ...(wasActiveContainer ? { activeContainerId: undefined } : {}),
            };
        }

        case 'addTab': {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.containerId]: {
                        ...(state.byId[action.containerId] ?? {}),
                        activeTabId: action.tab.id,
                        tabIds: [...(state.byId[action.containerId]?.tabIds ?? []), action.tab.id],
                    },
                },
            };
        }

        case 'removeTab': {
            const id = action.containerId;

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [id]: {
                        ...(state.byId[id] ?? {}),
                        tabIds: (state.byId[id]?.tabIds ?? []).filter((x) => x !== action.tabId),
                        activeTabId: action.newActiveTabId,
                    },
                },
            };
        }

        case 'updateActiveTab': {
            const id = action.containerId;

            if (!state.byId[id]?.tabIds.includes(action.tabId)) {
                // if the container doesn't have this tab, don't set it to active
                return state;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [id]: {
                        ...(state.byId[id] ?? {}),
                        activeTabId: action.tabId,
                    },
                },
            };
        }

        case 'setActiveContainer': {
            return {
                ...state,
                activeContainerId: action.containerId,
            };
        }

        case 'moveTab': {
            const tabKeysCopy = [...(state.byId[action.containerId]?.tabIds ?? [])];
            const draggedTabKey = tabKeysCopy[action.startIndex]; // get the tab key to move
            tabKeysCopy.splice(action.startIndex, 1); // remove the tab key from its current position
            tabKeysCopy.splice(action.endIndex, 0, draggedTabKey); // insert the tab key at its new position

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.containerId]: {
                        ...(state.byId[action.containerId] ?? {}),
                        tabIds: tabKeysCopy,
                    },
                },
            };
        }

        case 'setMaximizedContainer': {
            return {
                ...state,
                maximizedContainerId: action.containerId,
            };
        }

        default:
            return state;
    }
};
