import { getTopIdeasRecords, ideaLoader, ideaRecordLoader } from './http/ideas';
import type { QueryClient, QueryObserverOptions } from '@tanstack/query-core';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { ConcreteIdea, IdeaRecord, TopIdeas } from '@thinkalpha/platform-ws-client/contracts/ideas/index.js';
import type { ResourceQuery, ResourceQueryResponseWithMeta } from 'src/contracts/resource-query';
import {
    getStrategyRecordsQuery as getIdeaRecordsQueryService,
    getStrategyRecordsCountQuery as getStrategyRecordsCountQueryService,
} from 'src/lib/strategies';
import { ONE_HOUR } from 'src/lib/util/timeConstants';
import type { LibraryQueryResult } from 'src/models/LibraryModel';

/**
 * Get strategy records, which are objects that contain the basic database metadata of the strategy
 */
export const getIdeaRecordByIdQuery = (id: string): UseQueryOptions<IdeaRecord> => {
    return {
        queryKey: ['idea-records', id],
        queryFn: () => ideaRecordLoader.load(id),
    };
};

export const getIdeaByIdQuery = (id: string | null): UseQueryOptions<ConcreteIdea> => {
    return {
        queryKey: ['idea', id],
        queryFn: () => ideaLoader.load(id!),
        enabled: Boolean(id),
    };
};

export function getIdeaRecordsQuery(
    resourceQuery: ResourceQuery,
    enabled = true,
): QueryObserverOptions<ResourceQueryResponseWithMeta<IdeaRecord>> {
    return {
        queryKey: ['ideas', 'records', resourceQuery],
        queryFn: () => getIdeaRecordsQueryService(resourceQuery!),
        enabled,
    };
}

export function getIdeaRecordsForLibraryQuery(
    resourceQuery: ResourceQuery,
    enabled = true,
): QueryObserverOptions<LibraryQueryResult<IdeaRecord>> {
    return {
        queryKey: ['ideas', 'library-records', resourceQuery],
        queryFn: async () => {
            const result = await getIdeaRecordsQueryService(resourceQuery!).then(
                (res) => res as ResourceQueryResponseWithMeta<IdeaRecord>,
            );

            return {
                total: result.count,
                rows: result.results,
            };
        },
        enabled,
    };
}

export function removeIdeaFromRecordsQueries(idea: ConcreteIdea, queryClient: QueryClient): void {
    queryClient.setQueriesData(
        { queryKey: ['ideas', 'library-records'] },
        (data: LibraryQueryResult<IdeaRecord>): LibraryQueryResult<IdeaRecord> => {
            const rows = data.rows.filter((row: any) => row.id !== idea.id);
            const total = data.total - 1; // TODO validate we removed a record before updating the total?
            return { total, rows };
        },
    );

    queryClient.setQueriesData(
        { queryKey: ['ideas', 'records'] },
        (data: ResourceQueryResponseWithMeta<IdeaRecord>): ResourceQueryResponseWithMeta<IdeaRecord> => {
            const rows = data.results.filter((row: any) => row.id !== idea.id);
            return { ...data, results: rows };
        },
    );
}

export function getIdeaCountQuery(): QueryObserverOptions<ResourceQueryResponseWithMeta<IdeaRecord>, unknown, number> {
    return {
        queryKey: ['ideas', 'count'],
        queryFn: () => getStrategyRecordsCountQueryService(),
        select: (data) => data.count,
    };
}

export function getTopIdeasForUserQuery(): QueryObserverOptions<TopIdeas> {
    return {
        queryKey: ['ideas', 'top'],
        queryFn: () => getTopIdeasRecords(),
        staleTime: ONE_HOUR,
    };
}

export function removeIdeaFromTopIdeasQuery(idea: ConcreteIdea, queryClient: QueryClient): void {
    queryClient.setQueriesData({ queryKey: ['ideas', 'top'] }, (data: TopIdeas): TopIdeas => {
        const recentlyAccessed = data.recentlyAccessed.filter((x) => x.id !== idea.id);
        const recentlyCreated = data.recentlyCreated.filter((x) => x.id !== idea.id);
        return { recentlyAccessed, recentlyCreated };
    });
}
