import type {
    AcceptLocateOrderAction,
    CancelLocateOrderAction,
    ChangeAutoAcceptAction,
    CreateLocatesOrderAction,
    InjectLocateAction,
    OnOrderUpdateAction,
    OnStatusChangeAction,
    RejectLocateOrderAction,
    UploadSecurityAction,
    OnUpdateSoundAlertsAction,
    RejectAllLocatesOrdersAction,
    OnOrderRemoveAction,
    BootstrapLocatesAction,
    ConsiderLocatesSnackbarOrdersAction,
} from '../../actions/locates/locates';
import {
    setIsLocatesBoostrapComplete,
    setLocatesOrders,
    setLocatesStatus,
    setEnabledSoundAlerts,
    onOrderUpdate,
    resetLocates,
    onOrderRemove,
    considerLocatesSnackbarOrders,
} from '../../actions/locates/locates';
import type { SoundAlert } from '../../types/locates';
import { executeBootstrapLocatesFunctions } from './locatesBootstrap';
import { generateOptimisticOrders, generateOptimisticReceipts } from './optimisticLocates';
import { doesAccessGrantAllowRequest } from '@thinkalpha/common/util/permissions.js';
import type { ConcreteAccount } from '@thinkalpha/platform-ws-client/contracts/account.js';
import { t } from 'i18next';
import { all, call, getContext, put, select, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';
import { container } from 'src/StaticContainer';
import tambourineOneShot from 'src/assets/sounds/tambourineOneShot.webm';
import type { LocateOrder } from 'src/contracts/locates/orders';
import { LocateOrderStatus } from 'src/contracts/locates/orders';
import { getAccountsForUserQuery } from 'src/hooks/accounts/useAccounts';
import type { LocatesClient } from 'src/lib/locates';
import { getLocatesClient } from 'src/routes/widgets/LocatesWidget/hooks/useLocatesClient';
import type { AddWidgetToDashboardAction, RemoveContainerAction } from 'src/store/actions/container';
import { beginLocatesSetupProcess } from 'src/store/actions/locates/locatesSocket';
import type { AddTabAction, RemoveTabAction } from 'src/store/actions/tab';
import { setSnackbar } from 'src/store/actions/ui';
import type { CompleteWorkspaceResearchAction } from 'src/store/actions/workspace';
import type { StoredLocateOrder } from 'src/store/reducers/locates';
import { getContainerViewModels, getCurrentWorkspace, getWidgetTabViewModels } from 'src/store/selectors';
import {
    getEnabledLocatesSoundAlerts,
    getIsLocatesBootstrapComplete,
    getLocatesOrders,
} from 'src/store/selectors/locates/locates';
import type { ContainerViewModel, WidgetTabViewModel, WorkspaceViewModel } from 'src/store/types';
import type { SagaContext } from 'src/store/types/sagaContext';

const log = container.get('Logger').getSubLogger({ name: 'locates-sagas' });

function* bootstrapLocates() {
    try {
        const isLocatesBootstrapComplete: boolean = yield select(getIsLocatesBootstrapComplete);
        if (isLocatesBootstrapComplete) return;
        yield call(executeBootstrapLocatesFunctions);
        yield put(setIsLocatesBoostrapComplete(true));
    } catch (e) {
        if (e instanceof Error) {
            log.error({ message: 'Bootstrapping locates failed', error: e });
        } else {
            log.fatal({ message: 'Unknown exception', details: e });
        }
    }
}

function* handleCreateLocatesOrder(action: CreateLocatesOrderAction) {
    let optimisticId: string | null = null;
    try {
        const optimisticOrders = generateOptimisticOrders(action.order);
        optimisticId = optimisticOrders.optimisticOrder.optimisticOrderId!;
        yield put(onOrderUpdate([optimisticOrders.optimisticOrder], 'optimisticCreate'));

        const client: LocatesClient = yield call(getLocatesClient);

        const orders: LocateOrder[] = yield call(
            client.createLocatesOrder,
            optimisticOrders.newLocateOrder,
            action.autoAcceptPrice,
        );
        yield put(onOrderUpdate(orders, 'create'));
    } catch (e) {
        if (optimisticId) yield put(onOrderRemove([optimisticId], 'optimisticCreate'));

        let account: ConcreteAccount | undefined = undefined;
        const container: NonNullable<SagaContext['container']> = yield getContext('container');
        const queryClient = container.get('QueryClient');
        const fetchUserQuery = () => queryClient.fetchUserQuery(getAccountsForUserQuery('reader'));
        const accounts: ConcreteAccount[] = yield call(fetchUserQuery);
        account = accounts?.find((acc) => acc.id === action.order.accountId);
        const { quantity, symbol, providerId, accountId } = action.order;
        yield put(
            setSnackbar(
                t(
                    'widgets::lab::orders::create-error',
                    `Creating locates order for ${quantity} ${symbol} from ${providerId} with ${account?.name ?? 'an unknown account'} failed`,
                    {
                        quantity,
                        symbol,
                        providerId,
                        account: account?.name ?? 'an unknown account',
                    },
                ),
                { variant: 'error' },
            ),
        );

        if (e instanceof Error) {
            log.error({
                message: `Creating locates order for ${quantity} ${symbol} from ${providerId} with ${accountId} failed`,
                error: e,
            });
        } else {
            log.fatal({ message: 'Unknown exception', details: e });
        }
    }
}

function* handleInjectLocate(action: InjectLocateAction) {
    let optimisticIds: string[] | null = null;
    try {
        const optimisticReceipts = generateOptimisticReceipts(action.receipts);
        optimisticIds = optimisticReceipts.optimisticOrders.map((order) => order.optimisticOrderId!);
        yield put(onOrderUpdate(optimisticReceipts.optimisticOrders, 'optimisticInject'));

        const client: LocatesClient = yield call(getLocatesClient);

        const orders: LocateOrder[] = yield call(
            client.injectLocate,
            optimisticReceipts.newLocateReceipts,
            action.accountId,
        );
        yield put(onOrderUpdate(orders, 'inject'));

        let account: ConcreteAccount | undefined = undefined;
        const container: NonNullable<SagaContext['container']> = yield getContext('container');
        const queryClient = container.get('QueryClient');
        const fetchUserQuery = () => queryClient.fetchUserQuery(getAccountsForUserQuery('reader'));
        const accounts: ConcreteAccount[] = yield call(fetchUserQuery);
        account = accounts?.find((acc) => acc.id === action.accountId);

        const wordChoice = orders.length === 1 ? '1 locate' : `${orders.length} locates`;
        yield put(
            setSnackbar(`Injected ${wordChoice} to ${account?.name ?? action.accountId} successfully`, {
                variant: 'success',
            }),
        );
    } catch (e) {
        if (optimisticIds) yield put(onOrderRemove(optimisticIds, 'optimisticInject'));

        const wordChoice = action.receipts.length === 1 ? '1 locate' : `${action.receipts.length} locates`;
        yield put(setSnackbar(`Injecting ${wordChoice} to ${action.accountId} failed`, { variant: 'error' }));

        if (e instanceof Error) {
            log.error({ message: `Injecting ${wordChoice} to ${action.accountId} failed`, error: e });
        } else {
            log.fatal({ message: 'Unknown exception', details: e });
        }
    }
}

function* handleUploadSecurity(action: UploadSecurityAction) {
    const wordChoice = action.inventory.length === 1 ? 'security' : 'securities';
    try {
        const client: LocatesClient = yield call(getLocatesClient);

        // TODOLOCATES PLAT-5357 - utilize response to update maybe??
        yield call(client.uploadLocate, action.poolId, action.inventory);
        yield put(
            setSnackbar(`Uploaded ${action.inventory.length} ${wordChoice} to ${action.poolId} successfully`, {
                variant: 'success',
            }),
        );
    } catch (e) {
        yield put(setSnackbar(`Uploading ${wordChoice} to ${action.poolId} failed`, { variant: 'error' }));

        if (e instanceof Error) {
            log.error({ message: `Uploading ${wordChoice} to ${action.poolId} failed`, error: e });
        } else {
            log.fatal({ message: 'Unknown exception', details: e });
        }
    }
}

function* handleAcceptLocateOrder(action: AcceptLocateOrderAction) {
    try {
        const client: LocatesClient = yield call(getLocatesClient);

        const order: LocateOrder = yield call(client.acceptLocatesOrder, action.orderId);
        yield put(onOrderUpdate([order], 'accept'));
    } catch (e) {
        yield put(setSnackbar(`Accepting locates order ${action.orderId} failed`, { variant: 'error' }));

        if (e instanceof Error) {
            log.error({ message: `Accepting locates order ${action.orderId} failed`, error: e });
        } else {
            log.fatal({ message: 'Unknown exception', details: e });
        }
    }
}

function* handleRejectLocateOrder(action: RejectLocateOrderAction) {
    try {
        const client: LocatesClient = yield call(getLocatesClient);

        const order: LocateOrder = yield call(client.rejectLocatesOrder, action.orderId);
        yield put(onOrderUpdate([order], 'reject'));
    } catch (e) {
        yield put(setSnackbar(`Rejecting locates order ${action.orderId} failed`, { variant: 'error' }));

        if (e instanceof Error) {
            log.error({ message: `Rejecting locates order ${action.orderId} failed`, error: e });
        } else {
            log.fatal({ message: 'Unknown exception', details: e });
        }
    }
}

function* handleRejectAllLocateOrders(action: RejectAllLocatesOrdersAction) {
    try {
        const client: LocatesClient = yield call(getLocatesClient);

        const orders: LocateOrder[] = yield call(client.rejectAllLocatesOrders, action.accountIds);
        yield put(onOrderUpdate(orders, 'rejectAll'));
    } catch (e) {
        yield put(setSnackbar(`Rejecting all locates orders failed`, { variant: 'error' }));

        if (e instanceof Error) {
            log.error({ message: `Rejecting all locates orders failed`, error: e });
        } else {
            log.fatal({ message: 'Unknown exception', details: e });
        }
    }
}

function* handleCancelLocateOrder(action: CancelLocateOrderAction) {
    try {
        const client: LocatesClient = yield call(getLocatesClient);

        const order: LocateOrder = yield call(client.cancelLocatesOrder, action.orderId);
        yield put(onOrderUpdate([order], 'cancel'));
    } catch (e) {
        yield put(setSnackbar(`Cancelling locates order ${action.orderId} failed`, { variant: 'error' }));

        if (e instanceof Error) {
            log.error({ message: `Cancelling locates order ${action.orderId} failed`, error: e });
        } else {
            log.fatal({ message: 'Unknown exception', details: e });
        }
    }
}

function* handleChangeAutoAccept(action: ChangeAutoAcceptAction) {
    try {
        const client: LocatesClient = yield call(getLocatesClient);

        const order: LocateOrder = yield call(client.changeAutoAccept, action.orderId, action.autoAcceptInfo);
        yield put(onOrderUpdate([order], 'changeAutoAccept'));
    } catch (e) {
        yield put(setSnackbar(`Changing auto accept for order ${action.orderId} failed`, { variant: 'error' }));

        if (e instanceof Error) {
            log.error({ message: `Changing auto accept for order ${action.orderId} failed`, error: e });
        } else {
            log.fatal({ message: 'Unknown exception', details: e });
        }
    }
}

let alertSound: HTMLAudioElement;

function* handleOrderUpdate(action: OnOrderUpdateAction | OnOrderRemoveAction) {
    try {
        const orders: StoredLocateOrder[] = yield select(getLocatesOrders);
        const newOrders = [...orders]; // a copy of our currently stored orders that will be modified and/or added to

        if (action.type === 'locates::onOrderRemove') {
            const ordersAfterRemoval: StoredLocateOrder[] | null = yield handleOrderRemove(action);
            if (ordersAfterRemoval) yield put(setLocatesOrders(ordersAfterRemoval));
            return;
        }

        let isChanges = false; // whether or not any changes to any orders were actually made
        const snackbarOrders: LocateOrder[] = []; // any orders that should trigger a snackbar message
        let shouldAlert = false; // whether or not to play a sound alert
        // go through each order that is receiving an update from the action
        for (const newOrder of action.orders) {
            // try to find an optimistic order we have in redux that has the same optimisticOrderId as the new order
            const optimisticOrder = orders.find((order) => order.optimisticOrderId === newOrder.optimisticOrderId);
            // try to find an order we have in redux that has the same orderId as the new order
            const order = orders.find((order) => order.orderId === newOrder.orderId);

            // if we didn't find either order, then this is a completely new order, so just add it to the list
            if (!order && !optimisticOrder) {
                newOrders.unshift(newOrder);
                isChanges = true;
                continue;
            }

            // if there is an optimistic order but not an order, then this is an update to an optimistic order
            if (!order) {
                // if the optimistic order is removed, then we shouldn't add or update anything
                if (optimisticOrder?.isRemoved) continue;

                if (optimisticOrder!.orderStatus === LocateOrderStatus.sent) {
                    // we found an optimistic order that we should replace
                    newOrders.splice(newOrders.indexOf(optimisticOrder!), 1, newOrder);
                    // if the order is changing to accepted or rejected, we should consider a snackbar message
                    if (
                        newOrder.orderStatus === LocateOrderStatus.accepted ||
                        newOrder.orderStatus === LocateOrderStatus.rejected
                    ) {
                        snackbarOrders.push(newOrder);
                    }

                    isChanges = true;
                    continue;
                } else {
                    // we have an order with the same optimisticOrderId, but not the same orderId
                    // meaning this is probably an ALL request and we should just add the new order
                    newOrders.push(newOrder);
                    isChanges = true;
                    continue;
                }
            }

            // if there is an order and an optimistic order, then this is an update to an order that exists in the table

            // if the order has been removed, then we shouldn't add or update anything
            if (order.isRemoved) continue;

            // because we're doing takeEvery (which we should), there is some (small) chance that
            // we will receive an update for an order which has already been updated with more relevant information
            // in this case; it's a bit unfortunate to have to do this check, but it's better than
            // updating the order with old information or losing the update entirely to takeLatest
            if (newOrder.lastUpdateTime.compareTo(order.lastUpdateTime) < 0) {
                continue;
            }

            const currentEnabledSoundAlerts: SoundAlert[] = yield select(getEnabledLocatesSoundAlerts);

            // check if the order has an account that is selected in a widget that has sound alerts enabled
            if (currentEnabledSoundAlerts.some((soundAlert) => soundAlert.accountId === newOrder.accountId)) {
                // only orders that are changing to provided should ever alert
                if (
                    order.orderStatus !== LocateOrderStatus.provided &&
                    newOrder.orderStatus === LocateOrderStatus.provided
                ) {
                    // if we haven't created the alert sound yet, add it
                    if (!alertSound) {
                        alertSound = new Audio(tambourineOneShot);
                    }
                    shouldAlert = true;
                }
            }

            // the order already exists, so remove the old one and add the new one
            newOrders.splice(newOrders.indexOf(order), 1, newOrder);

            // if the order is changing to accepted or rejected, we should consider a snackbar message
            if (
                (newOrder.orderStatus === LocateOrderStatus.accepted &&
                    order.orderStatus !== LocateOrderStatus.accepted) ||
                (newOrder.orderStatus === LocateOrderStatus.rejected &&
                    order.orderStatus !== LocateOrderStatus.rejected)
            ) {
                snackbarOrders.push(newOrder);
            }

            isChanges = true;
        }

        // no new orders were found
        if (!isChanges) return;
        // there were changes, so update the store
        yield put(setLocatesOrders(newOrders));
        // if any orders are changing to accepted or rejected, we should consider sending snackbar messages
        if (snackbarOrders.length > 0) yield put(considerLocatesSnackbarOrders(snackbarOrders));
        // play the sound after updating the order so that we don't play the sound before the user can see the update
        if (shouldAlert) alertSound.play();
    } catch (e) {
        const actionWord = action.type === 'locates::onOrderRemove' ? 'Removing' : 'Updating';
        let updatedIds = '';
        if (action.type === 'locates::onOrderRemove') updatedIds = action.removeIds.join(', ');
        else updatedIds = action.orders.map((o) => o.orderId).join(', ');

        yield put(
            setSnackbar(`${actionWord} locates orders failed for orders with ids: ${updatedIds}`, { variant: 'error' }),
        );

        if (e instanceof Error) {
            log.error({ message: `${actionWord} locates orders failed for orders with ids: ${updatedIds}`, error: e });
        } else {
            log.fatal({ message: 'Unknown exception', details: e });
        }
    }
}

function* handleOrderRemove(action: OnOrderRemoveAction) {
    try {
        const orders: StoredLocateOrder[] = yield select(getLocatesOrders);
        const newOrders = [...orders]; // a copy of our currently stored orders that will be modified and/or added to
        let isChanges = false; // whether or not any changes to any orders were actually made

        // go through each order id that is going to be removed
        for (const id of action.removeIds) {
            // try to find an optimistic order we have in redux that has the same optimisticOrderId as the new order
            const optimisticOrder = orders.find((order) => order.optimisticOrderId === id);
            // try to find an order we have in redux that has the same orderId as the new order
            const order = orders.find((order) => order.orderId === id);

            // if either order was found, then we need to mark isRemoved as true
            if (optimisticOrder) {
                const updatedOptimisticOrder = { ...optimisticOrder, isRemoved: true };
                newOrders.splice(newOrders.indexOf(optimisticOrder), 1, updatedOptimisticOrder);
                isChanges = true;
            }

            if (order) {
                const updatedOrder = { ...order, isRemoved: true };
                newOrders.splice(newOrders.indexOf(order), 1, updatedOrder);
                isChanges = true;
            }
        }

        // no new orders were found
        if (!isChanges) return null;
        // there were changes, so update the store
        return newOrders;
    } catch (e) {
        if (e instanceof Error) {
            log.error({ message: `Failed to remove orders with ids: ${action.removeIds.join(', ')}`, error: e });
        } else {
            log.fatal({ message: 'Unknown exception', details: e });
        }

        return null;
    }
}

function* handleConsiderLocatesSnackbarOrders(action: ConsiderLocatesSnackbarOrdersAction) {
    try {
        const successSnackbarMessages: string[] = []; // stores accept messages, so the notif is green
        const errorSnackbarMessages: string[] = []; // stores reject messages, so the notif is red

        // get accounts
        let accounts: ConcreteAccount[] = [];
        const container: NonNullable<SagaContext['container']> = yield getContext('container');
        const queryClient = container.get('QueryClient');

        const fetchUserQuery = () => queryClient.fetchUserQuery(getAccountsForUserQuery('reader'));
        accounts = yield call(fetchUserQuery);

        // loop through orders to figure out which ones to show in the snackbar
        // then for applicable orders assemble the message and add it to the appropriate array
        for (const order of action.orders) {
            // if order isn't accepted or rejected, ignore
            if (order.orderStatus !== LocateOrderStatus.accepted && order.orderStatus !== LocateOrderStatus.rejected) {
                continue;
            }
            // see if account of the order exists and if the user is an owner of the account
            const account = accounts.find((acc) => acc.id === order.accountId);
            if (
                // no account at all
                !account ||
                // account, but inherited in a way that doesn't matter
                !account.permissions.effectiveDirectAccess ||
                // account assigned, but not directly owned
                !doesAccessGrantAllowRequest(account.permissions.effectiveDirectAccess, 'owner')
            ) {
                continue; // silence
            }

            const priceMessage = order.price ? ` @ ${order.price}` : '';
            // if it is and accepted, queue message
            if (order.orderStatus === LocateOrderStatus.accepted) {
                successSnackbarMessages.push(
                    `Accepted ${order.quantity} shares of ${order.symbol}${priceMessage} for ${account.name}`,
                );
            }
            // if it is and rejected, queue message
            else if (order.orderStatus === LocateOrderStatus.rejected) {
                errorSnackbarMessages.push(
                    `Rejected ${order.quantity} shares of ${order.symbol}${priceMessage} for ${account.name}`,
                );
            }
        }

        // if there are no messages, don't do anything
        if (successSnackbarMessages.length + errorSnackbarMessages.length === 0) return;

        // if there's only one message, just show it
        if (successSnackbarMessages.length + errorSnackbarMessages.length === 1) {
            const message = successSnackbarMessages.length > 0 ? successSnackbarMessages[0] : errorSnackbarMessages[0];
            yield put(setSnackbar(message, { variant: successSnackbarMessages.length > 0 ? 'success' : 'error' }));
        }
        // if there's multiple combine messages on multiple lines
        else {
            const message = `${successSnackbarMessages.join('\n')}\n${errorSnackbarMessages.join('\n')}`;
            yield put(setSnackbar(message, { variant: 'success', style: { whiteSpace: 'pre-line' } }));
        }
    } catch (e) {
        const orderIds = action.orders.map((order) => order.orderId).join(', ');
        if (e instanceof Error) {
            log.error({
                message: `Failed to consider locates snackbar orders for the following orderIds: ${orderIds}`,
                error: e,
            });
        } else {
            log.fatal({ message: 'Unknown exception', details: e });
        }
    }
}

function* handleStatusChange(action: OnStatusChangeAction) {
    try {
        const isLocatesBootstrapComplete: boolean = yield select(getIsLocatesBootstrapComplete);

        if (action.status === 'disconnected' && isLocatesBootstrapComplete) {
            // when we reconnect, we want to re-bootstrap and pull all the data
            // just in case it updated while we were disconnected
            // so when the socket disconnects, we tell the store we should re-bootstrap
            yield put(setIsLocatesBoostrapComplete(false));
        }

        yield put(setLocatesStatus(action.status));
    } catch (e) {
        yield put(setSnackbar(`Updating locates status failed ${e}`, { variant: 'error' }));

        if (e instanceof Error) {
            log.error({ message: `Updating locates status failed`, error: e });
        } else {
            log.fatal({ message: 'Unknown exception', details: e });
        }
    }
}

function* onUpdateSoundAlerts(action: OnUpdateSoundAlertsAction) {
    try {
        const currentEnabledSoundAlerts: SoundAlert[] = yield select(getEnabledLocatesSoundAlerts);

        if (action.enabled) {
            if (action.accountIds.length === 0) return;
            const convertedSoundAlerts: SoundAlert[] = action.accountIds.map((accountId) => ({
                accountId,
                source: action.source,
            }));
            const soundAlerts: SoundAlert[] = [...currentEnabledSoundAlerts];

            convertedSoundAlerts.forEach((soundAlert) => {
                // only add the sound alert if it doesn't already exist from the same source
                // if multiple widgets have the same account selected and have sound alerts enabled
                // then we want to keep track of all of those, even if the accountId is the same
                if (
                    !soundAlerts.some(
                        (currentSoundAlert) =>
                            currentSoundAlert.accountId === soundAlert.accountId &&
                            currentSoundAlert.source === soundAlert.source,
                    )
                ) {
                    soundAlerts.push(soundAlert);
                }
            });

            // remove any sound alerts that are for the same source and aren't in this action
            // since we receive all accounts selected for a widget in this action
            // if it's not in the action, we know it was deselected
            const filteredAlerts = soundAlerts.filter(
                (soundAlert) =>
                    soundAlert.source !== action.source ||
                    (soundAlert.source === action.source && action.accountIds.includes(soundAlert.accountId)),
            );

            yield put(setEnabledSoundAlerts(filteredAlerts));
        } else {
            // the user has disabled sound alerts for this widget
            // so remove all sound alerts for this source
            const soundAlerts: SoundAlert[] = currentEnabledSoundAlerts.filter(
                (soundAlert) => !(soundAlert.source === action.source),
            );
            yield put(setEnabledSoundAlerts(soundAlerts));
        }
    } catch (e) {
        yield put(setSnackbar(`Updating locates sound alerts failed ${e}`, { variant: 'error' }));

        if (e instanceof Error) {
            log.error({ message: `Updating locates sound alerts failed`, error: e });
        } else {
            log.fatal({ message: 'Unknown exception', details: e });
        }
    }
}

function* listenForLocatesInWorkspace(
    action:
        | AddWidgetToDashboardAction
        | CompleteWorkspaceResearchAction
        | RemoveContainerAction
        | AddTabAction
        | RemoveTabAction,
) {
    try {
        if (
            (action.type === 'addTab' && action.tab.widget.type === 'locates') ||
            (action.type === 'addWidgetToDashboard' &&
                (('widgetType' in action.widget && action.widget.widgetType === 'locates') ||
                    ('baseWidgetType' in action.widget && action.widget.baseWidgetType === 'locates')))
        ) {
            // if we're adding a locates widget or tab and we haven't bootstrapped locates yet
            // (which means there wasn't a locates widget or tab in the workspace before)
            // then we need to bootstrap locates
            const isLocatesBootstrapped: boolean = yield select(getIsLocatesBootstrapComplete);
            if (!isLocatesBootstrapped) yield put(beginLocatesSetupProcess());
        } else if (action.type === 'removeContainer' || action.type === 'removeTab') {
            // when we remove a container or tab, check if there are any locates widgets or tabs left
            // if there aren't, clear the locates store

            // if locates isn't bootstrapped, we don't need to do anything because a locates widget wasn't in the workspace
            const isLocatesBootstrapped: boolean = yield select(getIsLocatesBootstrapComplete);
            if (!isLocatesBootstrapped) return;

            const workspace: WorkspaceViewModel | null = yield select(getCurrentWorkspace);
            if (!workspace) return;

            const containers: Record<string, ContainerViewModel> = yield select(getContainerViewModels);
            const tabs: Record<string, WidgetTabViewModel> = yield select(getWidgetTabViewModels);

            // get the tabs in the current workspace, but don't include the removed container or tab
            const currentWorkspaceTabs: WidgetTabViewModel[] = workspace.containerIds.flatMap((containerId) => {
                // if we just removed a container, don't include it
                if (action.type === 'removeContainer' && containerId === action.container.id) return [];

                const container = containers[containerId];
                if (!container) return [];

                return container.tabIds.flatMap((tabId) => {
                    // if we just removed a tab, don't include it
                    if (action.type === 'removeTab' && tabId === action.tabId) return [];

                    const tab = tabs[tabId];
                    if (!tab) return [];

                    return [tab];
                });
            });

            if (!currentWorkspaceTabs.some((tab) => tab.widget.type === 'locates')) {
                // if there isn't a locates widget in the workspace, we can clear any info in that store
                yield put(resetLocates());
            }
        } else if (action.type === 'completeWorkspaceResearch') {
            // when we finish researching a workspace, get the current workspace
            // and check if there are any locates widgets in it
            // if there are, re-bootstrap locates, otherwise clear the locates store

            const workspace: WorkspaceViewModel | null = yield select(getCurrentWorkspace);
            if (!workspace) return;

            const containers: Record<string, ContainerViewModel> = yield select(getContainerViewModels);
            const tabs: Record<string, WidgetTabViewModel> = yield select(getWidgetTabViewModels);
            const currentWorkspaceTabs = workspace.containerIds.flatMap((containerId) => {
                const container = containers[containerId];
                if (!container) return [];
                return container.tabIds.map((tabId) => tabs[tabId]);
            });

            if (currentWorkspaceTabs.some((tab) => tab.widget.type === 'locates')) {
                // if there is a locates widget in the workspace, we need to setup the locates store
                yield put(beginLocatesSetupProcess());
            } else {
                // if there isn't, we can clear any info in that store
                yield put(resetLocates());
            }
        }
    } catch (e) {
        if (e instanceof Error) {
            log.error({ message: 'Listening for locates in workspace failed', error: e });
        } else {
            log.fatal({ message: 'Unknown exception', details: e });
        }
    }
}

export function* locatesSagas() {
    yield all([
        takeLeading<BootstrapLocatesAction['type']>('locates::bootstrap', bootstrapLocates),
        takeLatest<CreateLocatesOrderAction['type']>('locates::newOrderRequest', handleCreateLocatesOrder),
        takeLatest<InjectLocateAction['type']>('locates::inject', handleInjectLocate),
        takeLatest<UploadSecurityAction['type']>('locates::uploadSecurity', handleUploadSecurity),
        takeLatest<AcceptLocateOrderAction['type']>('locates::accept', handleAcceptLocateOrder),
        takeLatest<RejectLocateOrderAction['type']>('locates::reject', handleRejectLocateOrder),
        takeLatest<RejectAllLocatesOrdersAction['type']>('locates::rejectAll', handleRejectAllLocateOrders),
        takeLatest<CancelLocateOrderAction['type']>('locates::cancel', handleCancelLocateOrder),
        takeLatest<ChangeAutoAcceptAction['type']>('locates::changeAutoAccept', handleChangeAutoAccept),
        takeEvery<OnUpdateSoundAlertsAction['type']>('locates::onUpdateSoundAlerts', onUpdateSoundAlerts),
        takeEvery<ConsiderLocatesSnackbarOrdersAction['type']>(
            'locates::considerLocatesSnackbarOrders',
            handleConsiderLocatesSnackbarOrders,
        ),

        // takeLeading because we don't want to handle quick status changes at once
        // since it will look bad to the user; the saga also uses delay()
        // if it's updating from connecting -> disconnected, which means
        // we want to use takeLeading, otherwise we could end up with
        // a bunch of delay()'s ending together and setting the status
        // to disconnected when it shouldn't be
        takeLeading<OnStatusChangeAction['type']>('locates::onStatusChange', handleStatusChange),

        // takeEvery because if we receive an update for one order,
        // then immediately another update for a different order,
        // we wouldn't want to cancel the original update
        takeEvery<(OnOrderUpdateAction['type'] | OnOrderRemoveAction['type'])[]>(
            ['locates::onOrderUpdate', 'locates::onOrderRemove'],
            handleOrderUpdate,
        ),

        // takeEvery because we want to listen for whenever any of these actions happen
        // if you add a widget or tab, if it was a locates widget or tab, make sure locates is bootstrapped
        // if you remove a widget or tab, check if there are any locates widgets or tabs left, and clear the locates store if not
        // if you load a workspace, check if there are any locates widgets or tabs in it, and re-bootstrap locates if so
        takeEvery(
            ['addWidgetToDashboard', 'addTab', 'removeContainer', 'removeTab', 'completeWorkspaceResearch'],
            listenForLocatesInWorkspace,
        ),
    ]);
}
