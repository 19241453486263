import type { Idea } from '@thinkalpha/platform-ws-client/contracts/ideas/index.js';

export type UserDoubleClickedSymbolFromTableAction = {
    type: 'userDoubleClickedSymbolFromTable';
    symbol: string;
    tabId: string;
};

export const userDoubleClickedSymbolFromTable = (
    tabId: string,
    symbol: string,
): UserDoubleClickedSymbolFromTableAction => ({
    symbol,
    tabId,
    type: 'userDoubleClickedSymbolFromTable',
});

export type SetResultsStrategyAction = {
    type: 'setResultsStrategy';
    tabId: string;
    strategy: Idea;
};

export const setResultsStrategy = (tabId: string, strategy: Idea): SetResultsStrategyAction => ({
    type: 'setResultsStrategy',
    tabId,
    strategy,
});

export type ResultsWidgetActions = UserDoubleClickedSymbolFromTableAction | SetResultsStrategyAction;
