import { container } from 'src/StaticContainer';
import type { IndicatorImportRefViewModel } from 'src/contracts/dictionary-view-model';
import type { Strategy } from 'src/contracts/strategy';
import { getDefaultWorkspace, getWorkspaceById } from 'src/lib/workspaces';
import type {
    ContainerLayoutViewModel,
    ContainerViewModel,
    WidgetTabViewModel,
    WorkspaceViewModel,
} from 'src/store/types';
import {
    createWorkspaceViewModel,
    createContainerLayoutViewModel,
    createContainerViewModel,
    generateWidgetTabViewModels,
} from 'src/store/types/workspaceViewModels';

type ExplodedWorkspaceViewModel = {
    workspaceViewModel: WorkspaceViewModel;
    layoutViewModels: ContainerLayoutViewModel[];
    containerViewModels: ContainerViewModel[];
    widgetTabViewModels: WidgetTabViewModel[];
    importRefs: IndicatorImportRefViewModel[];
};

export async function getWorkspaceViewModelSetup(workspaceId: 'default' | string): Promise<ExplodedWorkspaceViewModel> {
    // Remove double-fetch of default and current workspaces when we get correct default workspace information from BFFE

    const queryClient = container.get('QueryClient');
    const defaultWorkspaceP = queryClient.fetchUserQuery({
        queryFn: async () => {
            const defaultWorkspace = await getDefaultWorkspace(true).catch(() => null);
            if (!defaultWorkspace) return null;
            return defaultWorkspace;
        },
        queryKey: ['workspaces', 'default'],
        // TODO: Cache-invalidate default workspace query when any workspace is saved
        gcTime: 0,
    });
    const workspaceP =
        workspaceId === 'default'
            ? defaultWorkspaceP
            : queryClient.fetchUserQuery({
                  queryFn: async () => {
                      const workspace = await getWorkspaceById(workspaceId).catch(() => null);
                      if (!workspace) return workspace;
                      return workspace;
                  },
                  queryKey: ['workspaces', workspaceId],
                  // TODO: Cache-invalidate default workspace query when any workspace is saved
                  gcTime: 0,
              });

    const [defaultWorkspace, workspace] = await Promise.all([defaultWorkspaceP, workspaceP]);

    if (!workspace) {
        throw new Error('Workspace not found');
    }

    // Perform research on all indicators stored here
    const containerStrategies = workspace.containers
        .flatMap((x) => x.tabs)
        .flatMap((x) => {
            const { widget } = x;
            if (widget.type === 'if-then-builder') {
                if (widget.strategy && typeof widget.strategy !== 'string') {
                    return [widget.strategy];
                }
            } else if (widget.type === 'results') {
                return widget.strategies?.filter((x) => x && typeof x !== 'string') ?? [];
            }

            return [];
        });

    const allImportRefs = [...containerStrategies]
        // Todo: why do i have to explicitly type guard this one? it should be implicit from typeof x !== 'string
        .filter((x): x is Strategy<IndicatorImportRefViewModel> => typeof x !== 'string')
        .flatMap((x) => x.plan.imports);

    // Remove additional argument for default workspace ID when we get correct default workspace information from BFFE
    const workspaceViewModel = createWorkspaceViewModel(workspace, workspace.id === defaultWorkspace?.id);

    const layoutViewModels = workspace.containers.map<ContainerLayoutViewModel>((container) =>
        createContainerLayoutViewModel(container.layout, container.id),
    );

    const containerViewModels = workspace.containers.map<ContainerViewModel>((container) =>
        createContainerViewModel(container),
    );

    const widgetTabViewModels: WidgetTabViewModel[] = await generateWidgetTabViewModels(workspace);

    return {
        workspaceViewModel,
        layoutViewModels,
        widgetTabViewModels,
        containerViewModels,
        importRefs: allImportRefs,
    };
}
