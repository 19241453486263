import type { Tag } from '@thinkalpha/platform-ws-client/contracts/dictionary.js';
import { createInstance } from 'src/api';
import { appConfig } from 'src/lib/config';

const axios = createInstance({ baseURL: appConfig.api });

export async function getDictionaryTags(): Promise<Tag[]> {
    const { data } = await axios.get<Tag[]>('/dictionary/tags/indexed');
    return data;
}
