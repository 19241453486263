import { getWorkspaceRecords } from './http/workspace';
import type { FetchQueryOptions } from '@tanstack/query-core';
import type { WorkspaceConfig, WorkspaceRecord } from '@thinkalpha/platform-ws-client/contracts/workspace.js';
import type { ResourceQueryResponseWithMeta } from 'src/contracts/resource-query';
import { ONE_HOUR } from 'src/lib/util/timeConstants';
import { getWorkspaceConfig } from 'src/lib/workspaces';

export const getWorkspaceConfigQuery = (): FetchQueryOptions<WorkspaceConfig> => ({
    queryKey: ['workspace-config'],
    queryFn: () => getWorkspaceConfig(),
    staleTime: ONE_HOUR,
});

/**
 * Get workspace records for a user
 *
 * @returns an object with queryKey, queryFn, and enabled
 */
export function getWorkspaceRecordsQuery(): FetchQueryOptions<ResourceQueryResponseWithMeta<WorkspaceRecord>> {
    return {
        queryKey: ['workspace', 'records'],
        queryFn: () => getWorkspaceRecords({ sortBy: 'createdAt desc', limit: 250 }),
    };
}
