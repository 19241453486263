import {
    getAlphaLensDefaultWidget,
    getDefaultTopbarFormulas,
    getFormulasFromToolbarItems,
    getOrderEntryTickers,
    getPinnedUniverses,
    getSuggestedColumns,
    getToolbarItems,
} from './http/userPreference';
import { type QueryObserverOptions } from '@tanstack/query-core';
import type { DictionaryBackedData } from '@thinkalpha/platform-ws-client/contracts/dictionary.js';
import { type OrderEntryTicker } from '@thinkalpha/platform-ws-client/contracts/user-preference.js';
import type { TemplateColumnBase } from 'src/contracts/column-template';
import { type IndicatorFormulaViewModel } from 'src/contracts/dictionary-view-model';
import { type AlphaLensContent } from 'src/contracts/workspace';
import { ToolbarItemDTO } from 'src/features/toolbar/components/ToolbarWidgets/dtos/toolbarItemDTO';

/**
 * Get pinned universe ids for a user.
 */
export function getPinnedUniversesQuery(): QueryObserverOptions<string[]> {
    return {
        queryKey: ['user-preferences', 'pinned-universes'],
        queryFn: () => getPinnedUniverses(),
    };
}

/**
 * Get toolbar items for a user.
 */
export function getToolbarItemsQuery({ enabled = true }: { enabled?: boolean } = {}): QueryObserverOptions<
    ToolbarItemDTO[]
> {
    return {
        queryKey: ['user-preferences', 'toolbar-items'],
        queryFn: async () => {
            const items = await getToolbarItems();
            return items.map((item) => ToolbarItemDTO.from(item));
        },
        enabled,
    };
}

export function getAlphaLensDefaultWidgetQuery(): QueryObserverOptions<AlphaLensContent> {
    return {
        queryKey: ['user-preferences', 'alpha-lens-widget-model-default'],
        queryFn: () => getAlphaLensDefaultWidget(),
    };
}

export function getDefaultTopbarFormulasQuery(): QueryObserverOptions<DictionaryBackedData[]> {
    return {
        queryKey: ['default-formulas'],
        queryFn: () => getDefaultTopbarFormulas(),
    };
}

export function getFormulasFromToolbarItemsQuery(
    defaultFormulas?: DictionaryBackedData[],
): QueryObserverOptions<IndicatorFormulaViewModel[]> {
    return {
        queryFn: () => getFormulasFromToolbarItems(defaultFormulas ?? []),
        queryKey: ['default-formulas-toolbar-items', JSON.stringify(defaultFormulas ?? [])],
    };
}

export function getOrderEntryTickersQuery(): QueryObserverOptions<OrderEntryTicker[]> {
    return {
        queryFn: () => getOrderEntryTickers(),
        queryKey: ['order-entry-tickers'],
    };
}

export function getSuggestedColumnsQuery(suggestedColumnsKey?: string): QueryObserverOptions<TemplateColumnBase[]> {
    return {
        queryKey: ['user-preferences', 'suggested-columns', suggestedColumnsKey],
        queryFn: () => getSuggestedColumns(suggestedColumnsKey ?? ''),
        enabled: !!suggestedColumnsKey,
    };
}
