import { type ScreenerPlanDTO } from 'src/features/screener/dtos/ScreenerPlanDTO';
import { type ThinkAlphaQueryClient } from 'src/lib/config/query-client';
import { createTable } from 'src/lib/table';
import { SourceStage } from 'src/lib/tableStages';

export const getTotalRowsCountQuery = () => {
    return {
        queryKey: ['all-tickers-rows-count-tcr'],
        queryFn: async () => {
            const leafStage = SourceStage.allForKey('ticker').build();
            return createTable({ leafStage });
        },
        staleTime: 0,
        gcTime: 0,
    };
};

/**
 * Currently Screener specific, we will need to handle the possible null from plan.toStage to use with scanner
 */
export const getFilteredRowsCountQuery = (
    plan: ScreenerPlanDTO,
    queryClient: ThinkAlphaQueryClient,
    userViewKey: string,
) => {
    return {
        queryKey: ['screener-rows-filtered-count-tcr', plan.toContract(), userViewKey],
        queryFn: async () => {
            const stage = (await plan.toStage(null, queryClient, false)).build();
            return createTable({ leafStage: stage, userViewKey });
        },
        staleTime: 0,
        gcTime: 0,
    };
};
