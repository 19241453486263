import { Instant } from '@js-joda/core';
import { ConcreteIdea, Idea, IfThenResearchPlan } from '@thinkalpha/platform-ws-client/contracts/ideas/index.js';
import type { Universe } from '@thinkalpha/platform-ws-client/contracts/universe.js';
// eslint-disable-next-line no-restricted-imports
import debounce from 'lodash/debounce';
import { from } from 'rxjs';
import { IndicatorImportRefModel } from 'src/dtos/IndicatorImportRef';
import { ReactiveInjectable, reacts, inject, injectable } from 'src/features/ioc';
import { ConditionGroupModel } from 'src/features/searchAlpha/dtos/ConditionGroupModel';
import { conditionGroupModelFromIfThenGroup } from 'src/features/searchAlpha/dtos/ConditionGroupModel/conditionGroupModelFromIfThenGroup';
import { conditionGroupModelToIfThenGroup } from 'src/features/searchAlpha/dtos/ConditionGroupModel/conditionGroupModelToIfThenGroup';
import { ConditionModel } from 'src/features/searchAlpha/dtos/ConditionModel';
import { CONDITION_CHANGE_EVENT } from 'src/features/searchAlpha/types';
import { createStrategyWithMappedImports, putStrategyP } from 'src/lib/strategies';
import { getUniverseById } from 'src/lib/universes';
import { type SearchAlphaWidgetModel } from 'src/models/SearchAlphaWidgetModel';
import { getIndicatorFromRefQuery } from 'src/queries/dictionary';
import { getIdeaByIdQuery } from 'src/queries/ideas';
import { updateTopStrategiesStrategyName, upsertRecentlyCreatedStrategyQueryData } from 'src/queries/strategies';
import { createDefaultSearchAlphaWidget } from 'src/store/defaults/widget';
import type { ReactBindings } from 'src/types/bindings';
import { v4 } from 'uuid';

interface SearchAlphaWidgetModelState {
    rootGroup: ConditionGroupModel;
}

@injectable()
export class SearchAlphaWidgetModelImpl extends ReactiveInjectable implements SearchAlphaWidgetModel {
    #state: SearchAlphaWidgetModelState;

    get children() {
        return this.#state.rootGroup.children as ConditionGroupModel[];
    }

    constructor(
        @inject('ImportsManagerModel') @reacts public importsManager: ReactBindings['ImportsManagerModel'],
        @inject('QueryClient') private queryClient: ReactBindings['QueryClient'],
        @inject('SearchAlphaWidgetAdapterModel')
        @reacts
        private adapter: ReactBindings['SearchAlphaWidgetAdapterModel'], // THE ADAPTER
        @inject('ResultsAreaModel') public resultsAreaModel: ReactBindings['ResultsAreaModel'], // THE RESULTS AREA MODEL
    ) {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);

        this.#state = {
            rootGroup: new ConditionGroupModel({
                booleanOperator: 'and',
                children: [],
                id: v4(),
                isSelfEnabled: true,
                isExpanded: true,
                label: undefined,
                parent: false,
            }),
        };

        // TODO implement a change target subscription class
        const listener = this.#onChildChange.bind(this);
        this.#state.rootGroup.changeTarget.addEventListener(CONDITION_CHANGE_EVENT, listener);
        this.disposableStack.defer(() => {
            this.#state.rootGroup.changeTarget.removeEventListener(CONDITION_CHANGE_EVENT, listener);
        });
    }

    get canSave() {
        const strategyRecord = this.adapter.strategy;
        if (!strategyRecord) {
            return false;
        }

        return Boolean(strategyRecord.name) && Boolean(strategyRecord.id) && this.hasUnsavedChanges;
    }

    get canSubmit() {
        let hasDirtyChild = false;
        for (const child of this.#iterateChildren(this.#state.rootGroup)) {
            if (child.isDirty) {
                hasDirtyChild = true;
            }
            if (child instanceof ConditionModel && child.errors !== null && child.isEnabledInSearch) {
                return false;
            }
            if (child instanceof ConditionModel && child.isPendingNlp) {
                return false;
            }
        }
        return hasDirtyChild;
    }

    #childBeingDragged: string | undefined;

    get childBeingDragged() {
        return this.#childBeingDragged;
    }

    set childBeingDragged(value: string | undefined) {
        this.#childBeingDragged = value;
        const conditionToMove = this.rootGroup.findChildById(value ?? '');
        this.rootGroup.processInFlightChild(conditionToMove as any);
    }

    /**
     * Creates a new strategy and swaps the contents of the widget model to the new strategy
     */
    createNewStrategy(): Idea & { plan: IfThenResearchPlan } {
        const newStrategy = createDefaultSearchAlphaWidget().idea;

        for (const child of this.#state.rootGroup.children) {
            this.#state.rootGroup.removeChild(child);
        }
        // This is safe to cast because it has no imports!
        this.adapter.strategy = newStrategy;

        // Note: this is duplicated from SearchAlphaWidgetModel.init
        {
            conditionGroupModelFromIfThenGroup(
                (newStrategy.plan as IfThenResearchPlan).root,
                this.#state.rootGroup,
                true,
            );

            if (this.#state.rootGroup.children.length === 0) {
                // default widgets with no strategy to have one conditionGroup
                this.#state.rootGroup.addNewConditionGroup();
            }

            for (const child of this.#iterateChildren(this.#state.rootGroup)) {
                child.setIsDirty(false);
                child.setIsUnsaved(false);
            }
        }

        this.resultsAreaModel.tableDestroyKey = v4();
        this.resultsAreaModel.lastSubmit = Instant.now();

        return newStrategy;
    }

    get hasUnsavedChanges() {
        for (const child of this.#iterateChildren(this.#state.rootGroup)) {
            if (child.isUnsaved) {
                return true;
            }
        }
        return false;
    }

    async init() {
        // this.disposableStack.use(from(this.resultsAreaModel).subscribe(() => this.#onResultsAreaModelChange()));
        // TODO: Subscribe to this.adapter.strategyNameEventTarget
        // const listener = ...
        // // META-TODO: Change this from event listener to Signal
        // this.adapter.strategyNameEventTarget.addEventListener('change', listener)
        // this.disposableStack.defer(() => this.adapter.strategyNameEventTarget.removeEventListener('change'), listener)
        // this.disposableStack.use(from(this.adapter).subscribe(() => this.#onAdapterChange()));
        this.disposableStack.use(from(this.adapter).subscribe(() => this.#onAdapterChange()));

        const strategy = this.adapter.strategy;

        // Initialize the strategy into the group
        await this.importsManager.addImportsFromRefs(
            strategy.plan.imports.map((importRef) => IndicatorImportRefModel.fromIndicatorImportRef(importRef)),
        );

        // Note: this is duplicated to SearchAlphaWidgetModel.createNewStrategy
        {
            conditionGroupModelFromIfThenGroup((strategy.plan as IfThenResearchPlan).root, this.#state.rootGroup, true);
            if (this.adapter.strategy.name) {
                this.rootGroup.setLabel(this.adapter.strategy.name);
            }

            if (this.#state.rootGroup.children.length === 0) {
                // default widgets with no strategy to have one conditionGroup
                this.#state.rootGroup.addNewConditionGroup();
            }

            for (const child of this.#iterateChildren(this.#state.rootGroup)) {
                child.setIsDirty(false);
                child.setIsUnsaved(false);
            }
        }

        await this.submit(/* force */ true);
    }

    /**
     * isDirty is a computed property that returns true if any of the conditions in the search have been modified from last submission
     */
    get isDirty() {
        for (const child of this.#iterateChildren(this.#state.rootGroup)) {
            if (child.isDirty) {
                return true;
            }
        }
        return false;
    }

    get isShowingIdeas() {
        return this.adapter.isShowingIdeas;
    }

    set isShowingIdeas(value: boolean) {
        this.adapter.isShowingIdeas = value;
    }

    get isShowingSearch() {
        return this.adapter.isShowingSearch;
    }

    set isShowingSearch(value: boolean) {
        this.adapter.isShowingSearch = value;
    }

    get isShowingResults() {
        return this.adapter.isShowingResults;
    }

    set isShowingResults(value: boolean) {
        this.adapter.isShowingResults = value;
    }

    get isTemplate() {
        return this.adapter.strategy.isTemplate;
    }

    *#iterateChildren(group: ConditionGroupModel): Generator<ConditionGroupModel | ConditionModel> {
        for (const child of group.children) {
            yield child;
            if (child instanceof ConditionGroupModel) {
                yield* this.#iterateChildren(child);
            }
        }
    }

    *#iterateSubmissionBlockers(): Generator<{ model: ConditionModel; isError: boolean; isPendingNlp: boolean }> {
        for (const child of this.#iterateChildren(this.#state.rootGroup)) {
            const isError = child instanceof ConditionModel && child.errors !== null && child.isEnabledInSearch;
            const isPendingNlp = child instanceof ConditionModel && child.isPendingNlp;
            if (isError || isPendingNlp) {
                yield { model: child, isError, isPendingNlp };
            }
        }
    }

    #markAsSaved() {
        for (const child of this.#iterateChildren(this.#state.rootGroup)) {
            child.setIsUnsaved(false);
        }
    }

    #onChildChange() {
        this.#updateStoreStrategy();
        this.rerender();
    }

    #onAdapterChange() {
        if (this.adapter.strategy.name ?? undefined !== this.rootGroup.label) {
            this.rootGroup.setLabel(this.adapter.strategy.name ?? undefined);
        }
    }

    get rootGroup() {
        return this.#state.rootGroup;
    }

    get runnableStrategy(): Idea & { plan: IfThenResearchPlan } {
        return this.adapter.strategy;
    }

    get splitPercentage() {
        return this.adapter.splitPercentage;
    }

    set splitPercentage(value: number) {
        this.adapter.splitPercentage = value;
    }

    @reacts private set state(state: SearchAlphaWidgetModelState) {
        this.#state = state;
    }

    async saveStrategy() {
        const id = this.adapter.strategy.id;
        if (!id) {
            throw new Error('Cannot save strategy without an id');
        }

        this.#updateStoreStrategy();
        const strategy = (await this.#updateStoreStrategy.flush()!) satisfies Idea & { plan: IfThenResearchPlan };

        this.resultsAreaModel.lastSubmit = Instant.now();

        const { plan, defaultUniverseId, name, description } = strategy;

        const updatedStrategy = await putStrategyP(id, { plan, defaultUniverseId, name, description });
        updateTopStrategiesStrategyName(updatedStrategy, this.queryClient);
        this.queryClient.invalidateQueries({
            queryKey: ['strategy-records', id],
        });
        this.queryClient.invalidateQueries({
            predicate: (query) => {
                const [name, params] = query.queryKey;
                return name === 'play-strategy' && (params as any).strategies.includes(id);
            },
        });

        const strategyWithImports = await this.#updateIdeaWithImports(updatedStrategy);

        this.#markAsSaved();
        this.adapter.strategy = strategyWithImports;

        return updatedStrategy;
    }

    async saveStrategyAs(name: string) {
        this.rootGroup.setLabel(name);

        this.#updateStoreStrategy();
        const strategy = {
            ...(await this.#updateStoreStrategy.flush()!),
            name,
        } satisfies Idea & { plan: IfThenResearchPlan };

        this.resultsAreaModel.lastSubmit = Instant.now();

        const newStrategy = (await createStrategyWithMappedImports(strategy)) as ConcreteIdea & {
            plan: IfThenResearchPlan;
        };

        upsertRecentlyCreatedStrategyQueryData(newStrategy, this.queryClient);

        const strategyWithImports = await this.#updateIdeaWithImports(newStrategy);

        this.#markAsSaved();
        this.adapter.strategy = strategyWithImports;

        return newStrategy;
    }

    get strategyId(): string | null {
        return this.adapter.strategy.id || null;
    }

    get strategyName(): string | null {
        return this.adapter.strategy.name || null;
    }

    get submissionBlockers(): { errorCount: number; pendingNlpCount: number } {
        const blockers = { errorCount: 0, pendingNlpCount: 0 };
        for (const { isError, isPendingNlp } of this.#iterateSubmissionBlockers()) {
            if (isError) {
                blockers.errorCount++;
            }
            if (isPendingNlp) {
                blockers.pendingNlpCount++;
            }
        }
        return blockers;
    }

    async submit(force = false) {
        if (force || this.canSubmit) {
            this.#updateStoreStrategy();
            await this.#updateStoreStrategy.flush();
            this.resultsAreaModel.lastSubmit = Instant.now();

            for (const child of this.#iterateChildren(this.#state.rootGroup)) {
                child.setIsDirty(false);
            }
        } else {
            for (const { model } of this.#iterateSubmissionBlockers()) {
                if (model instanceof ConditionModel) {
                    // If we haven't focused a field yet, look it up in the WeakMap using key `model` and if there's an element, call `.focus()` and set hasFocused true
                    model.needsAttention = true;
                }
            }
        }
    }

    /**
     * Updates the IfThen strategy in the Redux store which is saved to workspace and run via results view
     */
    #updateStoreStrategy = debounce(async (): Promise<Idea & { plan: IfThenResearchPlan }> => {
        const imports = this.importsManager.imports.map((imp) => imp.toRef().toContractType());
        const name = this.rootGroup.label;
        const plan: IfThenResearchPlan = {
            root: conditionGroupModelToIfThenGroup(this.#state.rootGroup),
            imports,
        };
        const newStrategy = await this.#updateIdeaWithImports({
            ...this.adapter.strategy,
            name: name ?? null,
            plan,
        });

        this.adapter.strategy = newStrategy;

        return newStrategy;
    }, 1000);

    async #updateIdeaWithImports(
        idea: Idea & { plan: IfThenResearchPlan },
    ): Promise<Idea & { plan: IfThenResearchPlan }> {
        const indicators = await Promise.all(
            idea.plan.imports.map((ref) => this.queryClient.fetchQuery(getIndicatorFromRefQuery(ref))),
        );

        const strategyWithImports: Idea & { plan: IfThenResearchPlan } = {
            ...idea,
            plan: {
                ...idea.plan,
                imports: indicators.map((indicator) => ({
                    ...indicator,
                    alias: idea.plan.imports.find(
                        (ref) => ref.id === indicator.id && ref.version === indicator.version,
                    )!.alias,
                })),
            },
        };

        return strategyWithImports;
    }

    async useStrategyById(id: string) {
        const strategy = await this.queryClient.fetchUserQuery(getIdeaByIdQuery(id));

        const toSearchAlpha = (ideaInput: ConcreteIdea): Idea & { plan: IfThenResearchPlan } => {
            if (ideaInput.plan.type === 'if-then' || ideaInput.plan.type === undefined) {
                return ideaInput as unknown as Idea & { plan: IfThenResearchPlan };
            }
            throw new Error('Tried to load invalid idea type into SearchAlpha widget');
        };

        const idea = toSearchAlpha(strategy);

        let universe: Universe | undefined;
        if (idea.defaultUniverseId) {
            // Because I am powerful and under a deadline, I will personally assure you that this is the correct query key:
            universe = await this.queryClient.fetchUserQuery({
                queryKey: ['universe', idea.defaultUniverseId],
                queryFn: () => getUniverseById(idea.defaultUniverseId!),
            });
        }

        this.importsManager.clear();
        await this.importsManager.addImportsFromRefs(
            idea.plan.imports.map((ref) => IndicatorImportRefModel.fromIndicatorImportRef(ref)),
        );

        for (const child of this.#state.rootGroup.children) {
            this.#state.rootGroup.removeChild(child);
        }
        conditionGroupModelFromIfThenGroup(idea.plan.root, this.#state.rootGroup, true);

        this.adapter.strategy = idea;
        this.adapter.universeId = universe?.id ?? null;

        for (const child of this.#iterateChildren(this.#state.rootGroup)) {
            child.setIsDirty(false);
            child.setIsUnsaved(false);
        }

        this.resultsAreaModel.tableDestroyKey = v4();
        if (this.canSubmit) {
            this.resultsAreaModel.lastSubmit = Instant.now();
        }
    }
}
