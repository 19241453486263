import type { LocalDate } from '@js-joda/core';
import type { EventType } from 'src/components/ui/EventList';

export type UserSetEventsUniverseAction = {
    type: 'userSetEventsUniverse';
    tabId: string;
    universeId: string | null;
};

export const userSetEventsUniverse = (tabId: string, universeId: string | null): UserSetEventsUniverseAction => ({
    type: 'userSetEventsUniverse',
    universeId: universeId,
    tabId,
});

export interface UserSetEventsSymbolFilter {
    type: 'userSetEventsSymbolFilter';
    symbolFilter: string | null;
    tabId: string;
}

export const userSetEventsSymbolFilter = (tabId: string, symbolFilter: string | null): UserSetEventsSymbolFilter => ({
    type: 'userSetEventsSymbolFilter',
    symbolFilter,
    tabId,
});

export interface UserSetEventsEventTypes {
    type: 'userSetEventsEventTypes';
    eventTypes: EventType[];
    tabId: string;
}

export const userSetEventsEventTypes = (tabId: string, eventTypes: EventType[]): UserSetEventsEventTypes => ({
    type: 'userSetEventsEventTypes',
    eventTypes,
    tabId,
});

export interface UserSetEventsGoToDate {
    type: 'userSetEventsGoToDate';
    tabId: string;
    date: LocalDate | null;
}

export const userSetEventsGoToDate = (tabId: string, date: LocalDate | null): UserSetEventsGoToDate => ({
    type: 'userSetEventsGoToDate',
    date,
    tabId,
});

export type EventsWidgetActions =
    | UserSetEventsUniverseAction
    | UserSetEventsSymbolFilter
    | UserSetEventsEventTypes
    | UserSetEventsGoToDate;
