import { rxapi, api } from '../api';
import type { NewWorkspace, UpdatedWorkspace, Workspace as LocalContractsWorkspace } from '../contracts/workspace';
import {
    type WorkspaceRecord,
    type WorkspaceConfig,
    type Workspace,
} from '@thinkalpha/platform-ws-client/contracts/workspace.js';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import type { ResourceQuery, ResourceQueryResponseWithMeta } from 'src/contracts/resource-query';
import { processQuery } from 'src/lib/paging';

interface WorkspaceDimensions {
    columnCount: number;
    rowCount: number;
}

interface MaybeWithDimensions {
    dimensions?: WorkspaceDimensions;
}

const addDimensionsToWorkspace = (
    workspace: (UpdatedWorkspace | NewWorkspace | Workspace) & MaybeWithDimensions,
): (UpdatedWorkspace | NewWorkspace | Workspace) & MaybeWithDimensions => {
    if (!('dimensions' in workspace)) {
        return {
            ...workspace,
            dimensions: {
                columnCount: 1,
                rowCount: 1,
            },
        };
    } else {
        return workspace;
    }
};

export const mapToCurrentUIExpectations = (workspace: Workspace): LocalContractsWorkspace => {
    const { dimensions, ...rest } = workspace;

    if (dimensions && (dimensions.columnCount !== 1 || dimensions?.rowCount !== 1)) {
        rest.containers = rest.containers.map((container) => {
            const { layout, ...rest } = container;
            return {
                ...rest,
                layout: {
                    ...layout,
                    h: layout.h / dimensions.rowCount,
                    w: layout.w / dimensions.columnCount,
                    x: layout.x / dimensions.columnCount,
                    y: layout.y / dimensions.rowCount,
                },
            };
        });
    }

    // hack time, strip formData and displayMode from simple order entry and order entry widgets
    // TODO remove once backend is updated to no longer return formData on these widgets
    rest.containers = rest.containers.map((container) => {
        const { tabs, ...rest } = container;
        return {
            ...rest,
            tabs: tabs.map((tab) => {
                if (tab.widget.type === 'simple-order-entry' || tab.widget.type === 'order-entry') {
                    // @ts-expect-error When this is no longer erroring it should be safe to remove, see comment above
                    delete tab.widget.formData;
                    // @ts-expect-error When this is no longer erroring it should be safe to remove, see comment above
                    delete tab.widget.displayMode;
                }
                return tab;
            }),
        };
    });

    // @ts-expect-error - The local contract types are all out of wack
    // We could try to map the types but I'm concerned that the app currently _works_ using the local contract types
    // and modifying responses to adhere to those types could have consequences.
    return rest;
};

export async function getDefaultWorkspace(expandImports?: true): Promise<LocalContractsWorkspace> {
    const res = await api
        .get<Workspace>(`/workspaces/default${expandImports ? '?$expand=imports' : ''}`)
        .then((res) => mapToCurrentUIExpectations(res.data));
    return res;
}

export async function getWorkspaceById(id: string): Promise<LocalContractsWorkspace> {
    const workspace = await api
        .get<Workspace>(`/workspaces/${id}?$expand=imports`)
        .then((res) => mapToCurrentUIExpectations(res.data));

    return workspace;
}

/**
 * This is an ugly hack. Cover your eyes.
 */
export function mapWatchlistDisplayModeToWidgetType(workspace: LocalContractsWorkspace): LocalContractsWorkspace {
    for (const container of workspace.containers) {
        for (const tab of container.tabs) {
            if (tab.widget.type === 'results' && (tab.widget as any).displayMode === 'watchlist') {
                (tab.widget as any).type = 'watchlist';
            }
        }
    }
    return workspace;
}

export function createWorkspace(workspace: NewWorkspace): Observable<LocalContractsWorkspace> {
    return rxapi
        .post<Workspace>(`/workspaces?$expand=imports`, addDimensionsToWorkspace(workspace))
        .pipe(map((x) => mapToCurrentUIExpectations(x.data)));
}

export function saveWorkspace(workspace: UpdatedWorkspace, expandImports?: true): Observable<LocalContractsWorkspace> {
    return rxapi
        .put<Workspace>(`/workspaces/${workspace.id}${expandImports ? '?$expand=imports' : ''}`, {
            ...addDimensionsToWorkspace(workspace),
            channels: [],
        })
        .pipe(map((x) => mapToCurrentUIExpectations(x.data)));
}

export function deleteWorkspace(id: string): Observable<void> {
    return rxapi.delete(`/workspaces/${id}`).pipe(
        map(() => {
            /* void */
        }),
    );
}

export function getWorkspacesQueryP(query: ResourceQuery): Promise<ResourceQueryResponseWithMeta<WorkspaceRecord>> {
    return api.get(`/workspaces/query${processQuery({ ...query, includeMeta: true })}`).then((x) => x.data);
}

export function getWorkspacesQuery(query: ResourceQuery): Observable<ResourceQueryResponseWithMeta<WorkspaceRecord>> {
    return rxapi.get(`/workspaces/query${processQuery({ ...query, includeMeta: true })}`).pipe(map((x) => x.data));
}
export function getWorkspacesCountQuery(): Observable<ResourceQueryResponseWithMeta<WorkspaceRecord>> {
    return rxapi.get(`/workspaces/query?$select=&$count=true`).pipe(map((x) => x.data));
}

export function getWorkspaceConfig(): Promise<WorkspaceConfig> {
    return api.get('/workspaces/config').then((res) => res.data);
}

export async function getTemplateWorkspaces(expandImports?: true): Promise<LocalContractsWorkspace[]> {
    const res = await api.get<Workspace[]>(`/workspaces/templates${expandImports ? '?$expand=imports' : ''}`);
    return res.data.map(mapToCurrentUIExpectations).map(mapWatchlistDisplayModeToWidgetType);
}
