import { type SearchAlphaActions } from 'src/store/actions/widgets/searchAlpha';
import type { IfThenBuilderWidgetViewModel, WidgetTabViewModel } from 'src/store/types';

export const ifThenReducer = (
    state: WidgetTabViewModel<IfThenBuilderWidgetViewModel>,
    action: SearchAlphaActions,
): WidgetTabViewModel<IfThenBuilderWidgetViewModel> => {
    switch (action.type) {
        case 'setSearchAlphaIdea': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    strategy: action.strategy,
                },
            };
        }

        default:
            return state;
    }
};
