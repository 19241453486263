import type { KnownAstNode } from '@thinkalpha/language-services';
import { AstNodeType, childrenOfNode } from '@thinkalpha/language-services';
import type { Indicator, IndicatorImport } from '@thinkalpha/platform-ws-client/contracts/dictionary.js';
import type { IndicatorViewModelOrImportViewModel } from 'src/lib/dictionaryModel';
import { isDictionaryViewModelFunctionDef } from 'src/lib/dictionaryModel';
import { naivelyFindMatches } from 'src/lib/naiveMatchFinder';
import { findIndexes } from 'src/lib/util/findIndexes';

const SourceName = 'Table Server';

export function tableServerAnalyzer(root: KnownAstNode, indicators: (Indicator | IndicatorImport)[]) {
    const children = childrenOfNode(root);
    if (root.type === AstNodeType.call) {
        const {
            functionDef,
            arguments: args,
            ranges: { eachArgument: argumentRanges, node: range },
            warnings,
        } = root;
        if (functionDef && isDictionaryViewModelFunctionDef(functionDef)) {
            const { params, presets, tableServerData, formula } = functionDef.indicator;
            const indexesOfSubscripts = findIndexes(params, (x) => !!x.tableServerData?.subscript);
            for (const index of indexesOfSubscripts) {
                const arg = args[index];
                if (arg && arg.type !== AstNodeType.const) {
                    const range = argumentRanges?.[index];
                    warnings.push({
                        source: SourceName,
                        text: 'Only constant values are supported by the strategy engine for this date/time field.',
                        range,
                    });
                }
            }

            const indexesOfBars = findIndexes(params, (x) => !!x.bars);
            for (const index of indexesOfBars) {
                const arg = args[index];
                if (arg && arg.type !== AstNodeType.const) {
                    const range = argumentRanges?.[index];
                    warnings.push({
                        source: SourceName,
                        text: 'Only constant values are supported by the strategy engine for the length of bars.',
                        range,
                    });
                }
            }

            const matchingPreset = naivelyFindMatches(root, functionDef.indicator, [], 'tableServerData');

            if (!tableServerData && !matchingPreset?.tableServerData && !formula) {
                warnings.push({
                    source: SourceName,
                    text: `The strategy engine cannot run strategies using this indicator or function${
                        presets.some((x) => x.tableServerData) ? ' with these arguments' : ''
                    }.`,
                    range,
                });
            }
        }
    }
    for (const child of children) {
        tableServerAnalyzer(child, indicators);
    }
}

/**
 * @deprecated Use tableServerAnalyzer instead
 */
export function tableServerAnalyzerLegacy(root: KnownAstNode, indicators: IndicatorViewModelOrImportViewModel[]) {
    const children = childrenOfNode(root);
    if (root.type === AstNodeType.call) {
        const {
            functionDef,
            arguments: args,
            ranges: { eachArgument: argumentRanges, node: range },
            warnings,
        } = root;
        if (functionDef && isDictionaryViewModelFunctionDef(functionDef)) {
            const { params, presets, tableServerData, formula } = functionDef.indicator;
            const indexesOfSubscripts = findIndexes(params, (x) => !!x.tableServerData?.subscript);
            for (const index of indexesOfSubscripts) {
                const arg = args[index];
                if (arg && arg.type !== AstNodeType.const) {
                    const range = argumentRanges?.[index];
                    warnings.push({
                        source: SourceName,
                        text: 'Only constant values are supported by the strategy engine for this date/time field.',
                        range,
                    });
                }
            }

            const indexesOfBars = findIndexes(params, (x) => !!x.bars);
            for (const index of indexesOfBars) {
                const arg = args[index];
                if (arg && arg.type !== AstNodeType.const) {
                    const range = argumentRanges?.[index];
                    warnings.push({
                        source: SourceName,
                        text: 'Only constant values are supported by the strategy engine for the length of bars.',
                        range,
                    });
                }
            }

            const matchingPreset = naivelyFindMatches(root, functionDef.indicator, [], 'tableServerData');

            if (!tableServerData && !matchingPreset?.tableServerData && !formula) {
                warnings.push({
                    source: SourceName,
                    text: `The strategy engine cannot run strategies using this indicator or function${
                        presets.some((x) => x.tableServerData) ? ' with these arguments' : ''
                    }.`,
                    range,
                });
            }
        }
    }
    for (const child of children) {
        tableServerAnalyzerLegacy(child, indicators);
    }
}
