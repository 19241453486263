import type { EventsWidgetActions } from 'src/store/actions/widgets/events';
import type { EventsWidgetViewModel, WidgetTabViewModel } from 'src/store/types';

export const eventsReducer = (
    state: WidgetTabViewModel<EventsWidgetViewModel>,
    action: EventsWidgetActions,
): WidgetTabViewModel<EventsWidgetViewModel> => {
    switch (action.type) {
        case 'userSetEventsUniverse': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    universeId: action.universeId,
                },
            };
        }

        case 'userSetEventsSymbolFilter': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    symbolFilter: action.symbolFilter,
                },
            };
        }

        case 'userSetEventsEventTypes': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    eventTypes: action.eventTypes,
                },
            };
        }

        case 'userSetEventsGoToDate': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    gotoDate: action.date,
                },
            };
        }

        default:
            return state;
    }
};
