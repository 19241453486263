import type { LocatesSocketConnectedEvent, LocatesSocketDisconnectedEvent } from '../actions/locates/locatesSocket';

export interface LocatePreferences {
    warningPreferences: {
        showAutoAcceptWarning: boolean;
        showIsPriceOverOneWarning: boolean;
        showNoCancelPossibleWarning: boolean;
        showETBWarning: boolean;
    };
}

export const defaultLocatePreferences: LocatePreferences = {
    warningPreferences: {
        showAutoAcceptWarning: true,
        showIsPriceOverOneWarning: true,
        showNoCancelPossibleWarning: true,
        showETBWarning: true,
    },
};

export const LOCATE_PREFERENCES_KEY = 'locate_preferences_1f2b3c';

export interface LocatesSocketRaceResult {
    success?: LocatesSocketConnectedEvent;
    failed?: LocatesSocketDisconnectedEvent;
    timedOut?: true;
}

export type SoundAlert = { accountId: string; source: string };
