import { WorkspaceService } from '.';
import { inject, injectable } from 'src/features/ioc';
import { waitForState } from 'src/store/waitForState';
import { type IsomorphicBindings } from 'src/types/bindings';

@injectable()
export class WorkspaceServiceImpl implements WorkspaceService {
    constructor(@inject('Store') private readonly store: IsomorphicBindings['Store']) {}

    async syncCurrentWorkspace(): Promise<void> {
        this.store.dispatch({ type: 'workspace-service::sync-current-workspace' });

        if (this.store.getState().workspace.currentWorkspaceStatus !== 'success') {
            await waitForState(this.store, (state) => state.workspace.currentWorkspaceStatus === 'success');
        }
    }
}
