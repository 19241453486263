import type { CustomEditor, CustomElement, CustomText } from './types';
import {
    Editor as SlateEditor,
    Element as SlateElement,
    type Node,
    Path as SlatePath,
    Range,
    Text as SlateText,
    Transforms as SlateTransforms,
} from 'slate';

export const Editor = {
    ...SlateEditor,
    isTextActive(editor: CustomEditor): boolean {
        if (editor.selection && !Range.isCollapsed(editor.selection)) return false;

        const [fragment] = Editor.nodes(editor, {
            match: (n) => Text.cheapIsText(n) && n.text !== '',
        });

        return Boolean(fragment);
    },
    /**
     * Poorly named, this iterates over "effective nodes", yielding:
     * - Text nodes that are direct children of the filter block
     * - Token nodes
     * - Whitespace nodes
     *
     * What is essentially ignored:
     * - The editor
     * - The filter block
     * - Zero-length text nodes
     * - Child text nodes of tokens
     */
    iterateFilterBlockChildren(editor: CustomEditor): Iterator<[CustomElement | CustomText, SlatePath]> {
        return Editor.nodes<CustomElement>(editor, {
            at: [],
            match: (n, p) => {
                if (p.length !== 2) {
                    return false;
                }
                if (Text.cheapIsText(n) && n.text === '') {
                    return false;
                }
                return true;
            },
        });
    },
};

export const Element = {
    ...SlateElement,
    cheapIsElement: (node: Node): node is CustomElement => {
        return 'type' in node;
    },
};

export const Path = {
    ...SlatePath,
    /**
     * Get the path of the previous Element in the document, skipping empty text nodes that surround inlines.
     */
    previousElement(editor: CustomEditor, path: SlatePath): SlatePath | null {
        const elementPath = path.slice(0, 2);
        if (!Path.hasPrevious(elementPath)) {
            return null;
        }

        const previous = [elementPath[0], elementPath[1] - 1];
        const [node] = Editor.node(editor, previous);
        if (Text.isText(node) && node.text === '') {
            return Path.previousElement(editor, previous);
        } else {
            return previous;
        }
    },
    /**
     * Get the path of the next Element in the document, skipping empty text nodes that surround inlines.
     */
    nextElement(editor: CustomEditor, path: SlatePath): SlatePath | null {
        // Hacky but there's no Editor.hasNext and I didn't want to implement it today
        try {
            const next = [path[0], path[1] + 1];
            const [node] = Editor.node(editor, next);
            if (Text.isText(node) && node.text === '') {
                return Path.nextElement(editor, next);
            } else {
                return next;
            }
        } catch (_e) {
            return null;
        }
    },
};

export const Text = {
    ...SlateText,
    cheapIsText: (node: Node): node is CustomText => {
        return 'text' in node;
    },
};

export const Transforms = {
    ...SlateTransforms,
    /**
     * Replace text of current element with new text
     */
    replaceElementText(editor: CustomEditor, text: string, elementPath: SlatePath): void {
        const path = elementPath.slice(0, 1);
        Transforms.insertText(editor, text, { at: path });
        Transforms.setSelection(editor, {
            anchor: { path: [...path, 0], offset: text.length },
            focus: { path: [...path, 0], offset: text.length },
        });
    },
};
