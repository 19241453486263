import { processQuery } from './paging';
import type { TableCreationResultWithColumns } from '@thinkalpha/platform-ws-client/contracts/table.js';
import type {
    ConcreteUniverse,
    NewUniverse,
    Universe,
    UniversePatch,
    UniverseRecord,
} from '@thinkalpha/platform-ws-client/contracts/universe.js';
import { isAxiosError } from 'axios';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { rxapi, api } from 'src/api';
import { randomString } from 'src/lib/util/randomString';

export function getUniverses(): Promise<UniverseRecord[]> {
    return api.get<UniverseRecord[]>(`/universes${processQuery({ limit: 250 })}`).then((x) => x.data);
}

export async function getUniverseById(id: string): Promise<ConcreteUniverse | undefined> {
    try {
        const response = await api.get<ConcreteUniverse>(`/universes/${id}`);

        return {
            ...response.data,
            aspects: response.data.aspects?.map((y) => ({ id: randomString(), ...y })),
        };
    } catch (error) {
        if (isAxiosError(error) && error.response?.status === 404) {
            return undefined;
        }

        throw error;
    }
}

/** @deprecated use saveUniverse instead */
export function saveUniverseRx(universe: NewUniverse | undefined): Observable<ConcreteUniverse> {
    return rxapi.post(`/universes`, universe).pipe(map((x): ConcreteUniverse => x.data));
}

export function saveUniverse(newUniverse: NewUniverse): Promise<ConcreteUniverse> {
    return api.post(`/universes`, newUniverse).then((x): ConcreteUniverse => x.data);
}

/** @deprecated use patchUniverse instead */
export function patchUniverseRx(id: string, patch: UniversePatch): Observable<ConcreteUniverse> {
    return rxapi.patch(`/universes/${id}`, patch).pipe(map((x) => x.data));
}

export const patchUniverse = async (universe: Universe): Promise<ConcreteUniverse> => {
    return (await api.patch(`/universes/${universe.id}`, universe)).data;
};

export function playTransientUniverse(universe: Readonly<Universe>): Observable<TableCreationResultWithColumns> {
    return rxapi.post<TableCreationResultWithColumns>(`/universes/build`, universe).pipe(map((x) => x.data));
}

export function deleteUniverse(id: string): Promise<ConcreteUniverse> {
    return api.delete(`/universes/${id}`).then((x) => x.data);
}

export function getDefaultUniverse(): Promise<ConcreteUniverse> {
    return api
        .get<ConcreteUniverse>(`/universes/default`)
        .then((x) => x.data)
        .then((x) => ({
            ...x,
            aspects: x.aspects?.map((y) => ({ id: randomString(), ...y })),
        }));
}

export const getWatchlistsQuery = async (): Promise<UniverseRecord[]> => {
    return (await api.get<UniverseRecord[]>(`/universes${processQuery({ filter: 'aspects/$count eq 0', limit: 250 })}`))
        .data;
};
