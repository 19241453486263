import { intentBlockingMiddleware } from '../middleware/intentBlocking';
import { nativeNavigationHelper } from '../middleware/nativeNavigationHelper';
import { createRootReducer } from '../reducers';
import { rootSaga } from '../sagas';
import { addSubscribeSelector } from '../subscribeSelector';
import type { SagaContext } from '../types';
import { composeWithDevTools } from '@redux-devtools/extension';
import { TypedContainerModule } from '@thinkalpha/common/ioc/TypedContainer.js';
import { addRunFunction } from '@thinkalpha/ui-core/src/store/runnable';
import type { History } from 'history';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { applyMiddleware, compose, legacy_createStore as createStore } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';
import createSagaMiddleware from 'redux-saga';
import { type IsomorphicBindings } from 'src/types/bindings';

export const localStoreModule = new TypedContainerModule<IsomorphicBindings>((bind) => {
    let history: History;
    if (APP_ELECTRON) {
        history = createMemoryHistory();
    } else {
        history = createBrowserHistory();
    }

    const { routerReducer, routerMiddleware, createReduxHistory } = createReduxHistoryContext({
        history,
        showHistoryAction: true,
    });

    bind('Store')
        .toDynamicValue((ctx) => {
            const container = ctx.container;
            const context: SagaContext = {
                configService: container.get('ConfigService'),
                electronService: container.isBound('ElectronService') ? container.get('ElectronService') : undefined,
                loginScreenService: container.get('LoginScreenService'),
                oAuth2Client: container.get('OAuth2Service'),
                launchDarklyClient: container.get('LaunchDarklyClient'),
                container,
            };

            const sagaMiddleware = createSagaMiddleware({ context });

            const middlewares = [nativeNavigationHelper, intentBlockingMiddleware, routerMiddleware, sagaMiddleware];
            const middlewareEnhancer = applyMiddleware(...middlewares);

            const enhancers = compose(
                addSubscribeSelector,
                addRunFunction(() => {
                    const task = sagaMiddleware.run(rootSaga);
                    return () => task.cancel();
                }),
                composeWithDevTools(middlewareEnhancer),
            );

            // Casting here since the return type of `createStore` doesn't reflect the enhancers we're adding
            return createStore(createRootReducer({ routerReducer }), {}, enhancers) as IsomorphicBindings['Store'];
        })
        .inSingletonScope();

    bind('History')
        .toDynamicValue((ctx) => {
            const store = ctx.container.get('Store');
            return createReduxHistory(store);
        })
        .inSingletonScope();
});
