import type { IndicatorImportRefViewModel } from './dictionary-view-model';
import type { HasPermissionedFlags } from '@thinkalpha/common/contracts/permissions.js';
import type { BinaryLogicalOperator, ComparisonOperator } from '@thinkalpha/language-services';
import type { ConcreteResource } from '@thinkalpha/platform-ws-client/contracts/base-resource.js';

export enum IfThenFieldInView {
    formula = 'formula',
    nlp = 'nlp',
}

export interface Formula {
    formula?: string;
    naturalLanguage?: string;
    fieldInView: IfThenFieldInView;
}

export interface IfThenPartBase {
    enabled: boolean;
    id: string;
    description: string | null;
}

export interface IfThenLineBase extends IfThenPartBase {
    type: 'line';
}

export interface IfThenGuidedLine extends IfThenLineBase {
    mode: 'guided';
    lhs: Formula;
    operator: ComparisonOperator | null;
    rhs: Formula;
}

export interface IfThenFormulaLine extends IfThenLineBase, Formula {
    mode: 'formula';
}

export type IfThenLine = IfThenGuidedLine | IfThenFormulaLine;

export interface IfThenGroup extends IfThenPartBase {
    type: 'group';
    name: string | null;
    collapsed: boolean;
    operator: BinaryLogicalOperator;
    lines: IfThenPart[];
}

export type IfThenPart = IfThenGroup | IfThenLine;

export interface IfThenSavedCondition extends IfThenGroup, ConcreteResource, HasPermissionedFlags {
    key: string;
}

export interface NewIfThenSavedCondition
    extends Omit<IfThenSavedCondition, 'id' | 'permissions' | 'createdAt' | 'discardedAt' | 'isTemplate'> {}

export interface IfThenResearchPlan<
    TKey extends string = string,
    T extends IndicatorImportRefViewModel = IndicatorImportRefViewModel,
> {
    key?: TKey;
    type?: 'if-then';
    root: IfThenGroup;
    imports: T[];
}
