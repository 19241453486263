import type {
    FormulaServiceRequest,
    FormulaServiceRequestType,
    FormulaServiceResponse,
    FormulaServiceResponseForType,
} from './types';
import { Pool } from '@thinkalpha/common/util/pool.js';
import type { ConcreteDataType, ParserResult } from '@thinkalpha/language-services';
import { canConvert, getNameForDataType, iterateAst } from '@thinkalpha/language-services';
import { IndicatorImportRef } from '@thinkalpha/platform-ws-client/contracts/dictionary.js';
import { injectable } from 'inversify';
import { IndicatorImportRefModel } from 'src/dtos/IndicatorImportRef';
import { fakeConstStringParserResult } from 'src/lib/parser';
import type { AnalyzerFunction } from 'src/lib/parser';
import { reviveObject } from 'src/lib/serializer';
import type { FormulaService } from 'src/services/FormulaService';
import { v4 as uuid } from 'uuid';

@injectable()
export class FormulaServiceImpl implements FormulaService {
    #workerPool: Pool<Worker>;

    constructor() {
        this.#workerPool = new Pool<Worker>({
            objectFactory: () => new Worker(new URL('./worker.ts', import.meta.url), { type: 'module' }),
            maxSize: 12,
            initialSize: 3,
            autoGrow: true,
        });
    }

    async #sendWorkerRequest<T extends FormulaServiceRequestType>(
        request: FormulaServiceRequest & { type: T },
    ): Promise<FormulaServiceResponseForType<T>> {
        using workerPooledObject = await this.#workerPool.useWait();
        const worker = workerPooledObject.value;

        worker.postMessage(request);

        const res = await new Promise<FormulaServiceResponseForType<T>>((resolve, reject) => {
            const listener = (e: MessageEvent<FormulaServiceResponse>) => {
                if (e.data.id === request.id) {
                    if (e.data.type !== request.type && e.data.type !== 'error') {
                        throw new Error('Unexpected response type');
                    }

                    worker.removeEventListener('message', listener);
                    worker.removeEventListener('message', listener);
                    if (e.data.type === 'error') {
                        return reject(e.data.error);
                    }
                    const res = e.data as FormulaServiceResponseForType<T>;
                    return resolve(res);
                }
            };
            worker.addEventListener('message', listener);
        });
        return res;
    }

    async #sendWorkerParserRequest(request: FormulaServiceRequest & { type: 'parse' }): Promise<ParserResult> {
        const payload = await this.#sendWorkerRequest(request);
        iterateAst(payload.result.root).forEach((node) => Object.assign(node, reviveObject(node as any)));
        return payload.result;
    }

    async parse(
        formula: string,
        analyzers: AnalyzerFunction[] = [],
        equalsMode?: boolean,
        dataTypeRequired?: ConcreteDataType,
    ): Promise<ParserResult> {
        if (equalsMode && !formula.startsWith('=')) {
            return fakeConstStringParserResult(formula);
        }

        const parseableFormula = (equalsMode ? formula.slice(1) : formula) ?? '';

        let parserResult = await this.#sendWorkerParserRequest({
            type: 'parse',
            formula: parseableFormula,
            id: uuid(),
        });

        if (parserResult.root) {
            for (const analyzer of analyzers) analyzer(parserResult.root);
        }

        if (dataTypeRequired) {
            if (
                !parserResult.root ||
                !parserResult.root.dataType ||
                !canConvert(parserResult.root.dataType, dataTypeRequired)
            ) {
                const newError = {
                    text:
                        `Expected type ${getNameForDataType(dataTypeRequired)}` +
                        (parserResult.root && parserResult.root.dataType
                            ? `, but instead found ${getNameForDataType(parserResult.root.dataType)}`
                            : ''),
                    range: { start: 0, end: parserResult.text.length },
                    source: 'if-then',
                };
                parserResult = { ...parserResult, errors: [...parserResult.errors, newError], valid: false };
            }
        }

        return parserResult;
    }

    async normalize(formula: string, indicatorImports: IndicatorImportRef[]) {
        const aliasToRefMap = new Map(
            indicatorImports.map((x) => [x.alias, x instanceof IndicatorImportRefModel ? x.toContractType() : x]),
        );
        const res = await this.#sendWorkerRequest({
            type: 'normalize',
            formula,
            aliasToRefMap,
            id: uuid(),
        });
        return res.result;
    }

    async humanize(formula: string) {
        const res = await this.#sendWorkerRequest({ type: 'humanize', formula, id: uuid() });
        return res.result;
    }
}
