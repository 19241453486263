import type { IndicatorPresetViewModel } from '../contracts/dictionary-view-model';
import type { IndicatorViewModelOrImportViewModel } from './dictionaryModel';
import { isDictionaryViewModelFunctionDef } from './dictionaryModel';
import type { CallNode, FunctionDef, KnownAstNode } from '@thinkalpha/language-services';
import { analyzer, AstNodeType, compareNodesSemantically, parser, recurseParen } from '@thinkalpha/language-services';
import { isEqual, uniq } from 'es-toolkit';
import { container } from 'src/StaticContainer';

const log = container.get('Logger').getSubLogger({ name: 'naiveMatchFinder' });

// exported for testing
export function mapFunctionDefsToIndicators(sourceFunctionDefs: FunctionDef[]): IndicatorViewModelOrImportViewModel[] {
    return uniq(sourceFunctionDefs.filter(isDictionaryViewModelFunctionDef).map((x) => x.indicator));
}

export function naivelyFindMatches(
    node: CallNode,
    indicator: IndicatorViewModelOrImportViewModel,
    sourceFunctionDefs: FunctionDef[],
    requiredKey?: keyof Pick<IndicatorPresetViewModel, 'gaussData' | 'tableServerData'>,
): IndicatorPresetViewModel | undefined {
    // const { id, symbol, tableServerData, presets, params } = indicator;
    const { presets } = indicator;
    const args: (KnownAstNode | null)[] = node.arguments;
    const formulaPresets = presets.filter((x) => !!x.tableServerData && 'formula' in x.match);

    if (formulaPresets.length) {
        for (const formulaPreset of formulaPresets) {
            const match = formulaPreset.match as { formula: string; depth: number };
            // first parse
            const parserResult = parser(match.formula, {});
            if (!parserResult.valid || !parserResult.root) {
                log.warn({ message: `Encountered bad preset \`${formulaPreset}\` for indicator ${indicator.symbol}` });
                continue;
            }
            analyzer(parserResult.root, { functionDefs: sourceFunctionDefs });
            // find target comparison node
            const depth = Math.abs(match.depth);
            let compareNode: KnownAstNode | undefined = node;
            for (let i = 0; i < depth; i++) compareNode = compareNode?.parent as KnownAstNode | undefined;
            if (!compareNode) continue;
            if (!compareNodesSemantically(parserResult.root, compareNode, { ignoreParen: true })) {
                continue;
            }
            return formulaPreset;
        }
    }

    // we must do these second to ensure that we capture depth != 0 first
    const naiveArgs = args
        .map((arg) => recurseParen(arg))
        .map((arg) =>
            arg?.type === AstNodeType.const
                ? arg.value
                : arg?.type === AstNodeType.call && !arg.arguments.length
                  ? arg.functionDef?.id
                  : undefined,
        );
    const matchingPreset = presets.find(
        (x) =>
            (!requiredKey || !!x[requiredKey]) &&
            Array.isArray(x.match) &&
            x.match.every((x, i) => isEqual(x, naiveArgs[i])),
    );
    if (matchingPreset) return matchingPreset;
}
