import type { WorkspaceRecord } from '@thinkalpha/platform-ws-client/contracts/workspace.js';
import { firstValueFrom } from 'rxjs';
import type { ResourceQuery, ResourceQueryResponseWithMeta } from 'src/contracts/resource-query';
import { getWorkspacesQuery } from 'src/lib/workspaces';

const DEFAULT_QUERY: ResourceQuery = {
    limit: 200,
};

export async function getWorkspaceRecords(
    query: ResourceQuery = DEFAULT_QUERY,
): Promise<ResourceQueryResponseWithMeta<WorkspaceRecord>> {
    return firstValueFrom(getWorkspacesQuery(query));
}
