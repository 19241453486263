import type { CustomText, SlateTextDecoration } from '../../slate';
import type { CallNode, ConcreteDataType, ParserResult } from '@thinkalpha/language-services';
import type { ConcreteIndicator } from '@thinkalpha/platform-ws-client/contracts/dictionary.js';
import type { ReactEditor } from 'slate-react';
import type { IndicatorImportModel } from 'src/dtos/IndicatorImport';
import type { ReactiveInjectable } from 'src/features/ioc';
import type { AnalyzerFunction } from 'src/lib/parser';
import type { ImportsManagerModel } from 'src/models/ImportsManagerModel';

export const CODE_EDITOR_RESULT_CHANGE_EVENT = 'code-editor-result-change';

export type CodeEditorResultChangeEvent = CustomEvent<CodeEditorResult>;

export interface CodeEditorError {
    start: number;
    end: number;
    message: string;
}

export interface CodeEditorResult {
    result: ParserResult | null;
    code: string;
    errors: CodeEditorError[];
    isValid: boolean;
}

export interface EditorServiceParseOptions {
    analyzers?: AnalyzerFunction[];
    equalsMode?: boolean;
    dataTypeRequired?: ConcreteDataType;
}

export interface EditorDecorationCompletion {
    type: 'completion';
    query: string;
    selectedIndex: number;
}

export interface EditorDecorationErrors {
    type: 'errors';
}

export interface EditorDecorationInputHelper {
    type: 'input_helper';
    callNode: CallNode;
    inputHelperIndicator: IndicatorImportModel;
    params: string[];
    showIndicatorNextPreviousButtons: boolean;
    stack: string[];
}

export interface EditorDecorationNone {
    type: 'none';
}

export interface EditorDecorationPicker {
    type: 'picker';
    selectedIndex: number;
}

/**
 * Allows the Code Editor Service to maintain a draft state of a CallNode being modified
 */
interface CodeEditorServiceCallNodeDraft {
    cancelIndicatorNodeDraft(): void;
    commitIndicatorNodeDraft(): void;
    updateIndicatorNodeDraft(params: string[]): void;
}

/**
 * Allows the Code Editor Service to wrap the Slate editor instance with language-services integration
 */
interface CodeEditorServiceEditing {
    decorations: Map<CustomText, SlateTextDecoration>;

    isFocused: boolean;

    /**
     * This will be the result of the last completed parse.
     *
     * TODO: Change this type to `Promise<CodeEditorResult>` to retrieve the latest in-progress result
     */
    result: CodeEditorResult;

    resultChangeTarget: EventTarget;

    /**
     * Sync the focus state surrounding the editor with the editor service
     */
    setEditorFocus(isFocused: boolean): void;

    update(code: string): Promise<void>;

    /**
     * Change the behavior of the @thinkalpha/language-services parser
     */
    updateParseOptions(options: EditorServiceParseOptions): void;
}

/**
 * Allows the Code Editor Service to direct editor-associated floating UI elements
 */
interface CodeEditorServiceDecorations {
    acceptSuggestion(indicator: ConcreteIndicator): void;

    editorDecoration:
        | EditorDecorationCompletion
        | EditorDecorationErrors
        | EditorDecorationInputHelper
        | EditorDecorationNone
        | EditorDecorationPicker;

    updateCurrentIndicatorStackIndex(index: number): void;

    goToNextIndicator(): void;
    goToPreviousIndicator(): void;
}

export interface CodeEditorService
    extends ReactiveInjectable,
        CodeEditorServiceCallNodeDraft,
        CodeEditorServiceDecorations,
        CodeEditorServiceEditing {
    importsManager: ImportsManagerModel;

    /**
     * Provide the slate editor instance to the service
     */
    init(editor: ReactEditor, importsManager: ImportsManagerModel, initialValue: string): void;
}
