import type { Condition } from '../../scranner/types';
import { QueryClient } from '@tanstack/query-core';
import { fromQuery, handleQueryErrors } from '@thinkalpha/common/util/fromQuery.js';
import { IfThenFieldInView, type ScannerPlan } from '@thinkalpha/platform-ws-client/contracts/ideas/index.js';
import { map, shareReplay } from 'rxjs';
import { container } from 'src/StaticContainer';
import { SearchPlanDTO } from 'src/dtos/Idea/SearchPlanDTO';
import { type IndicatorImportModel } from 'src/dtos/IndicatorImport';
import {
    SCRANNER_CHANGE_EVENT,
    type ScrannerChangeEvent,
    ScrannerPlanDTO,
} from 'src/features/scranner/dtos/ScrannerPlanDTO';
import { type ConditionGroupModel } from 'src/features/searchAlpha/dtos/ConditionGroupModel';
import { conditionGroupModelToIfThenGroup } from 'src/features/searchAlpha/dtos/ConditionGroupModel/conditionGroupModelToIfThenGroup';
import { type ThinkAlphaQueryClient } from 'src/lib/config/query-client';
import { type SlugMap } from 'src/lib/dictionary/dictionary';
import { FilterStage, SourceStage, type Stage } from 'src/lib/tableStages';
import { getWholeSlugMapQuery } from 'src/queries/slugMap';
import { type ReactBindings } from 'src/types/bindings';
import { v4 } from 'uuid';

const _log = container.get('Logger').getSubLogger({ name: 'feat:scanner:ScannerModelDTO' });

interface ScannerPlanDTOInitializer {
    isDirty: boolean;
    conditions: Condition[];
    mode: 'exclude' | 'include-any' | 'include-all';
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface ScannerPlanDTOState {
    //
}

const slugMap$ = fromQuery(new QueryClient({}), getWholeSlugMapQuery()).pipe(
    handleQueryErrors(),
    map((x) => x.data),
    shareReplay(1),
);

let slugMap: SlugMap | undefined;
slugMap$.subscribe((x) => {
    slugMap = x;
});

export class ScannerPlanDTO extends ScrannerPlanDTO {
    constructor(
        state: ScannerPlanDTOInitializer | undefined,
        queryClient: ThinkAlphaQueryClient,
        formulaService: ReactBindings['FormulaService'],
    ) {
        super(state, queryClient, formulaService);

        this.#formulaService = formulaService;

        this.#state = state ?? {};
    }

    #formulaService: ReactBindings['FormulaService'];

    override toContract(): ScannerPlan {
        return {
            ...super.toContract(),
            type: 'scanner',
        };
    }

    static async fromPlan(
        plan: ScannerPlan,
        queryClient: ReactBindings['QueryClient'],
        formulaService: ReactBindings['FormulaService'],
    ): Promise<ScannerPlanDTO> {
        return new ScannerPlanDTO(
            {
                ...(await ScrannerPlanDTO.fromPlanToInitializer(plan, queryClient, formulaService)),
            },
            queryClient,
            formulaService,
        );
    }

    #state: ScannerPlanDTOState;

    private set state(newState: ScannerPlanDTOState) {
        this.#state = newState;
        this.changeTarget.dispatchEvent(new CustomEvent(SCRANNER_CHANGE_EVENT) satisfies ScrannerChangeEvent);
    }

    private static makeEmptySearchPlan() {
        return SearchPlanDTO.fromPlanAndImports(
            {
                root: {
                    type: 'group',
                    name: 'TODO',
                    collapsed: false,
                    enabled: true,
                    lines: [
                        {
                            type: 'line',
                            enabled: true,
                            id: v4(),
                            mode: 'formula',
                            formula: 'true == false',
                            description: '',
                            fieldInView: IfThenFieldInView.formula,
                        },
                    ],
                    id: v4(),
                    description: '',
                    operator: 'and',
                },
                imports: [],
            },
            [],
        );
    }

    async toSearchPlan(): Promise<SearchPlanDTO> {
        if (!this.conditions.some((x) => x.enabled)) {
            return ScannerPlanDTO.makeEmptySearchPlan();
        }

        if (!slugMap) throw new Error("Slug map isn't loaded yet");

        const imports: IndicatorImportModel[] = [];

        let conditions: ConditionGroupModel | undefined;
        // eslint-disable-next-line prefer-const -- keeping the same pattern as the inclusion/exclusion lists
        conditions = await this.toConditionGroupModel(imports);
        if (!conditions) {
            return ScannerPlanDTO.makeEmptySearchPlan();
        }

        return SearchPlanDTO.fromPlanAndImports(
            {
                root: conditionGroupModelToIfThenGroup(conditions),
                imports: imports.values().toArray(),
            },
            imports,
        );
    }

    override async toStage(
        inputStage: Stage | null,
        queryClient: ThinkAlphaQueryClient,
        validationMode: boolean,
    ): Promise<FilterStage | null> {
        if (!validationMode) {
            const result = await this.renderToFormula();
            if (!result) return null;

            const { formula, imports } = result;

            return FilterStage.fromInputStage(
                inputStage ?? SourceStage.allForKey('ticker'),
                {
                    type: 'filter',
                    formula,
                    imports,
                },
                [],
            );
        } else {
            const result = await this.getValidationModeFormulaAndIndicators();
            if (!result) return null;

            const [bespokeIndicators, formula] = result;

            return FilterStage.fromInputStage(
                inputStage ?? SourceStage.allForKey('ticker'),
                {
                    type: 'filter',
                    formula: formula.formula,
                    imports: formula.imports,
                },
                bespokeIndicators,
            );
        }
    }

    async renderToFormula() {
        const searchPlanDTO = await this.toSearchPlan();
        return searchPlanDTO.renderToFormula();
    }

    async getValidationModeFormulaAndIndicators() {
        const searchPlanDTO = await this.toSearchPlan();
        return searchPlanDTO.getValidationModeFormulaAndIndicators();
    }
}
