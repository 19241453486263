import type { IndicatorImportViewModel, IndicatorImportRefViewModel } from 'src/contracts/dictionary-view-model';
import { getImportViewModelsFromRefs } from 'src/lib/dictionary/dictionary';
import { isFullIndicatorImport } from 'src/lib/util/dictionary';

export class ImportCacheManager {
    #keyToRefCache: Record<string, Promise<IndicatorImportViewModel>> = {};

    private makeKey(indicatorRef: IndicatorImportRefViewModel): string {
        return `${indicatorRef.id}__${indicatorRef.version}`;
    }

    async seed(imports: IndicatorImportViewModel[]) {
        imports.forEach((x) => {
            const key = this.makeKey(x);
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            if (!this.#keyToRefCache[key]) {
                this.#keyToRefCache[key] = Promise.resolve(x);
            }
        });
    }

    async getImports(indicatorRefs: IndicatorImportRefViewModel[]): Promise<IndicatorImportViewModel[]> {
        const actuallyFullImports = indicatorRefs.filter(isFullIndicatorImport);

        this.seed(actuallyFullImports);

        const uncachedIndicators = indicatorRefs.filter((x) => this.#keyToRefCache[this.makeKey(x)] === undefined);
        const newIndicators: Promise<Record<string, IndicatorImportViewModel>> =
            uncachedIndicators.length > 0
                ? getImportViewModelsFromRefs(uncachedIndicators).then((indicators) =>
                      indicators.reduce((all, curr) => ({ ...all, [this.makeKey(curr)]: curr }), {}),
                  )
                : Promise.resolve({});

        // Place in cache now, so that if we query while we are waiting for this result we don't fire off more XHRs than equired
        uncachedIndicators.forEach((x) => {
            const indicatorKey = this.makeKey(x);

            this.#keyToRefCache[indicatorKey] = newIndicators.then((newIndicators) => {
                return newIndicators[indicatorKey];
            });
        });

        const allRefs = (await Promise.all(indicatorRefs.map((x) => this.#keyToRefCache[this.makeKey(x)]))).filter(
            (x) => !!x,
        );

        return allRefs;
    }
}

export const importCacheManager = new ImportCacheManager();
