import { IdeaPlanDTO } from '.';
import { type IndicatorImportModel } from '../IndicatorImport';
import type {
    IfThenLine,
    IfThenGroup,
    IfThenResearchPlan,
} from '@thinkalpha/platform-ws-client/contracts/ideas/index.js';
import { strategyGenerator } from 'src/features/validationMode';
import { type ThinkAlphaQueryClient } from 'src/lib/config/query-client';
import { FilterStage, SourceStage, type Stage } from 'src/lib/tableStages';

interface SearchPlanConstructionOptions {
    imports: IndicatorImportModel[];
    root: IfThenGroup;
}

export class SearchPlanDTO extends IdeaPlanDTO {
    #imports: IndicatorImportModel[];
    #root: IfThenGroup;

    get imports(): IndicatorImportModel[] {
        return this.#imports;
    }

    private constructor(options: SearchPlanConstructionOptions) {
        super();

        const { imports, root } = options;

        this.#imports = imports;
        this.#root = root;
    }

    static fromPlanAndImports(plan: IfThenResearchPlan, imports: IndicatorImportModel[]): SearchPlanDTO {
        return new SearchPlanDTO({
            root: plan.root,
            imports: imports,
        });
    }

    override async toStage(
        inputStage: Stage | null,
        queryClient: ThinkAlphaQueryClient,
        validationMode: boolean,
    ): Promise<FilterStage | null> {
        if (!validationMode) {
            const result = this.renderToFormula();
            if (!result) return null;

            const { formula, imports } = result;

            return FilterStage.fromInputStage(
                inputStage ?? SourceStage.allForKey('ticker'),
                {
                    type: 'filter',
                    formula,
                    imports,
                },
                [],
            );
        } else {
            const result = await this.getValidationModeFormulaAndIndicators();
            if (!result) return null;

            const [bespokeIndicators, formula] = result;

            return FilterStage.fromInputStage(
                inputStage ?? SourceStage.allForKey('ticker'),
                {
                    type: 'filter',
                    formula: formula.formula,
                    imports: formula.imports,
                },
                bespokeIndicators,
            );
        }
    }

    async getValidationModeFormulaAndIndicators() {
        return await strategyGenerator(this, this.imports);
    }

    renderToFormula() {
        const formula = renderGroup(this.#root);

        if (!formula) return null;

        return { formula, imports: this.imports.map((i) => i.toRef().toContractType()) };
    }
}

/**
 * Functions for rendering different parts of an if-then strategy.
 *
 * @see {@link https://github.com/thinkalpha/platform-ws/blob/7d0040c27e0ad1d8c870ff547338be8d8e94d4cb/projects/app/src/services/if-then/impl.ts}
 */
export function renderLine(line: IfThenLine): string | undefined {
    if (line.mode === 'formula') return line.formula;
    const { lhs, rhs, operator } = line;

    // If all pieces of line are missing, consider it an empty line and disregard it
    if (
        (lhs.formula === undefined || lhs.formula.length === 0) &&
        (rhs.formula === undefined || rhs.formula.length === 0) &&
        operator === null
    ) {
        return undefined;
    }

    // If only certain pieces of line are missing, throw an error here, because it's not guaranteed that parser will catch the incomplete line
    if (
        lhs.formula === undefined ||
        lhs.formula.length === 0 ||
        rhs.formula === undefined ||
        rhs.formula.length === 0 ||
        operator === null
    ) {
        throw Error('Your strategy has an incomplete line.');
    }

    const combined = `${lhs.formula} ${operator ? operator : ''} ${rhs.formula}`;
    return combined;
}

export function renderGroup(group: IfThenGroup): string | undefined {
    if (!group.operator) throw new Error('No operator in group being converted to filter: ' + group.id);

    const pieces = group.lines
        .filter((x) => x.enabled)
        .map((line) => {
            if (line.type === 'group') {
                if (!line.lines.length) return undefined;
                return renderGroup(line);
            } else if (line.type === 'line') {
                return renderLine(line);
            } else {
                return undefined;
            }
        })
        .filter((x) => x)
        .map((x) => `(${x})`);

    if (!pieces.length) return undefined;

    return pieces.filter((x) => x).join(` ${group.operator} `);
}
