import { ConditionGroupModel } from '.';
import { conditionModelToIfThenLine } from '../ConditionModel/conditionModelToIfThenLine';
import { type IfThenGroup } from 'src/contracts/if-then';

export const conditionGroupModelToIfThenGroup = (conditionGroupModel: ConditionGroupModel): IfThenGroup => {
    return {
        id: conditionGroupModel.id,
        name: conditionGroupModel.label ?? null,
        enabled: conditionGroupModel.isSelfEnabled,
        operator: conditionGroupModel.booleanOperator === 'and' ? 'and' : 'or',
        collapsed: !conditionGroupModel.isExpanded,
        lines: conditionGroupModel.children.map((child) => {
            if (child instanceof ConditionGroupModel) {
                return conditionGroupModelToIfThenGroup(child);
            }

            return conditionModelToIfThenLine(child);
        }),
        type: 'group',
        description: null,
    };
};
