import { inject, injectable } from 'src/features/ioc';
import { setAccessToken as setLocatesClientAccessToken } from 'src/lib/locates/modern';
import { setAccessToken as setPushServiceAccessToken } from 'src/lib/push';
import { setSuperuserFlag, setAccessToken as setTableClientAccessToken } from 'src/lib/table';
import { getCurrentAccessToken, getClientCredentials, getCurrentIsSuperuserEnabledFlag } from 'src/store/selectors';
import type { IsomorphicBindings } from 'src/types/bindings';

/**
 * Syncs various data from the store to external services
 */
@injectable()
export class SyncInternalConfigurationsServiceImpl {
    constructor(@inject('Store') private readonly store: IsomorphicBindings['Store']) {
        this.store
            .subscribeSelector(getCurrentAccessToken, { leading: true })
            .handle((tokens) => this.syncAccessToken(tokens));
        this.store
            .subscribeSelector(getClientCredentials, { leading: true })
            .handle((creds) => this.syncClientCredentials(creds));
        this.store
            .subscribeSelector(getCurrentIsSuperuserEnabledFlag, { leading: true })
            .handle((flag) => this.syncIsSuperuserEnabledFlag(flag));
    }

    private syncClientCredentials(clientCredentials: ReturnType<typeof getClientCredentials>) {
        // Auth all the internal services to the new token
        setTableClientAccessToken(clientCredentials);
    }

    private syncAccessToken(accessToken?: string) {
        if (!accessToken) return;

        // Auth all the internal services to the new token
        setPushServiceAccessToken(accessToken);
        setTableClientAccessToken(accessToken);
        setLocatesClientAccessToken(accessToken);
    }

    private syncIsSuperuserEnabledFlag(flag: ReturnType<typeof getCurrentIsSuperuserEnabledFlag>) {
        setSuperuserFlag(flag);
    }
}
